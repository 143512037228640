.partner-landing-main-area {
    .hero-section {
        display: flex;
        align-items: center;
        background-color: #0f1868;
        padding: 50px 0;
        min-height: 430px;
        @media (max-width: 768px) {
            padding: 10px 0 0;
        }
        .main-heading-container {
            display: flex;
            align-items: left;
            @media (max-width: 768px) {
                font-size: 30px;
                text-align: left;
            }
            .main-heading {
                font-size: 44px;
                line-height: 52px;
                max-width:440px;
                color: #fff;
                font-weight: 600;
                min-width: 503px;
                @media (max-width: 1200px) {
                    font-size: 32px;
                    margin-bottom: 12px;
                    line-height: 37px;
                }
                @media (max-width: 768px) {
                    font-size: 25px;
                    min-width: auto;
                }
                color: #fff;
                font-weight: 600;
            }
        }
        .video-section-container {
            padding: 0;
        }
    }
    .schar-hero-background {
        background-image: url("../imgs/schar-hero-background.png");
        background-size: contain;
    }
    .ieit-hero-background {
        background-image: url("../imgs/ieit-hero-background.png");
        background-size: cover;
    }
    .abcd-hero-background {
        background-image: url("../imgs/ieit-hero-background.png");
        background-size: cover;
    }
    
    .livingston-hero-background {
        background-image: url("../imgs/livingston-hero-background.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: left;
        .main-heading {
            color: #0e1467 !important;
        }
        @media (max-width: 992px) {
            min-height: 200px;
        }
    }

    .zte-hero-background {
        background-image: url("../imgs/zte-hero-background.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: left;
        .main-heading {
            color: #0e1467 !important;
        }
        @media (max-width: 992px) {
            min-height: 200px;
        }
    }

    .sec-info {
        // border-bottom: 1px solid #ebebeb;
        
        p {
            font-size: 24px;
            text-align: left;
            margin: 30px 0;
            color: #fff;
            width: 616px;
            line-height: 28px;
            @media (max-width: 768px) {
                font-size: 13px;
                line-height: 17px;
                width: 100%;
            }
        }
        .color-text{
            color:#0e1467;
        }
        .sec-text{
            width: auto;
        }
    }

    .sec_title{
        .title_p{
            font-size: 30px;
            font-weight: bold;
            padding: 30px;
        }
        background-color: #fff;
    }
    .se-crs-info {
        padding: 50px 0;
        // max-width: 1200px;
        // margin: 0 auto;
       
        .sec-crs-row {
            .collection-info {
                // margin-top: 30px;
                @media (max-width: 768px) {
                    text-align: center;
                }
                .collection-img {
                    width: 100%;
                    max-width: 600px;
                    margin: 0 auto;
                    display: block;
                    margin-bottom: 15px;
                }
                .update-row{
                    display: flex;
                   
                    @media (max-width: 768px) {
                        flex-direction: column-reverse;
                    }    
                }
               
                h4 {
                    font-weight:400;
                    font-size:32px;
                    line-height: 38px;
                }
            }
            .practitioner-explore-btn {
                display: flex;
                // justify-content: center;
                margin-top: 20px;
                // display: none;
                text-transform: uppercase;
                @media (max-width: 768px) {
                    display: flex;
                    justify-content: center;
            }
                .btn-ghost{
                    background-color: #f3c465;
                }
            }
            
            p {
               
            margin-top: 10px;
            font-size: 14px;
            line-height: 24px;
            text-align: left;

            }
        }
        .learn-more-btn {
            margin-top: 20px;
            display: block;
            max-width: 175px;
            margin: 20px auto 15px;
        }

        .explore-course-btn-container {
            display: flex;
            // justify-content: center;
            margin-top: 20px;
            @media (max-width: 768px) {
                display: flex;
                justify-content: center;
        }
            .explore-course-btn {
                text-transform: uppercase;
                background-color: #f3c465;

            }
        }

        .sec_certi_series {
            background-color: #ddd;
        }
    }

    .livingston-course-img {
        display: block;
        margin: 0 auto;
        width: 100%;
        @media(max-width: 1200px) {
            max-width: 600px;
        }
    }

    .livingston-info-section {
        background-color: #f8f8f9;
        padding: 50px 0;

        .feature-row {
            padding: 50px 0;
           
            .text-container {
                display: flex;
                padding-left: 50px;
                // padding-top: 25px;

                @media (max-width: 768px) {
                    padding-left: 15px;
                    padding-top: 40px;
                }
                .sub-heading {
                    font-size: 24px;
                    margin-top: 10px;
                    font-weight:600;
                    line-height: 26px;
                }
                p {
                    margin-top: 10px;
                    font-size: 15px;
                    line-height: 21px;
                }
                .explore-course-btn-container {
                    margin-top: 20px;
                }
            }
        }

        p {
            margin-top: 10px;
            font-size: 15px;
            line-height: 21px;
        }

        @media (max-width: 768px) {
            .feature-row:nth-child(even) {
                flex-direction: column-reverse;
            }
        }
    }

    .featured-courses-section {
        padding: 50px 0;
        background-color: #fff;
        .col-md-12 {
            overflow: hidden;
        }
        .explore-course-btn-container {
            display: flex;
            justify-content: center;
            margin-top: 20px;
            .explore-course-btn {
                text-transform: uppercase;
                background-color:#f3c46557;
                border:1px solid #F3C465;
                .back-btn{
                    padding-left:17px;
                }
            }
        }
        
        .f_title{
            font-size:30px;
            font-weight:bold;
        }
    }

    .client-section {
        padding: 50px 0;
        // margin-bottom: -65px;
    }

    // .custom_section_crs{
    //     width:100%;
    //     border:1px solid #f8f8f9;
    //     min-height: 567px;
    //     .box-item{
    //         width:50%;
    //         float:left;
    //         height: 100%;
    //         @media (max-width:576px) {
    //             width:100%;
    //         }
    //         h4 {
    //             font-weight:400;
    //             font-size:32px;
    //             line-height: 38px;
    //             width: 544px;
    //             margin-bottom: 20px;
    //             @media (max-width: 768px) {
    //                 width:100%;
    //             }
    //         }
    //         .cont{
    //             margin-left:100px;
    //             margin-top:30px;
    //             margin-right: 20px;
    //             @media (max-width: 768px) {
    //                 margin:30px;
    //             }
    //         }
    //         .cnt_r{
    //             margin:30px;
    //         }
    //     }
    //     .img-box-right{
    //         width:100%;
    //         min-height: 567px;
    //         background-image: url('../../assets/imgs/business-meeting-1.jpg');
    //         background-repeat: no-repeat;
    //         background-size: cover;
    //         @media (max-width: 768px) {
    //             background-size: auto;
    //             min-height: 350px;
    //         }
    //     }

    //     .img-box-left{
    //         width:100%;
    //         min-height: 567px;
    //         background-image: url('../../assets/imgs/business-meeting-2.jpg');
    //         background-repeat: no-repeat;
    //         background-size: cover;
    //         @media (max-width: 768px) {
    //             background-size: auto;
    //             min-height: 350px;
    //         }
    //     }
    //     .practitioner-explore-btn {
    //         display: flex;
    //         // justify-content: center;
    //         margin-top: 20px;
    //         // display: none;
    //         text-transform: uppercase;
    //         .btn-ghost{
    //             background-color: #f3c465;
    //         }
    //     }
    //     .explore-course-btn-container {
    //         display: flex;
    //         // justify-content: center;
    //         margin-top: 20px;
    //         .explore-course-btn {
    //             text-transform: uppercase;
    //             background-color: #f3c465;

    //         }
    //     }
    // }
    // .custom_fluid{
    //     padding-left: 100px;
    //     @media (max-width: 768px) {
    //         padding-left: 20px;
    //     }
    // }

    

    .custom_section_crs{
        .col-rev{
            @media (max-width: 768px) {
                flex-direction: column-reverse;
            }
        }
        .box-item{
            // margin: 0 20px;
            @media (max-width: 768px) {
                margin-bottom:20px;
            }
            h4 {
                font-weight:400;
                font-size:32px;
                line-height: 38px;
                // width: 544px;
                margin-bottom: 20px;
                @media (max-width: 768px) {
                    width:100%;
                    text-align: center;
                }
            }

            .cont{
                margin-top:30px;
                margin-right:30px;
                p{
                    @media (min-width: 1000px) and  (max-width: 1050px){
                        line-height: 21px;
                      }
                }
            }

            .practitioner-explore-btn {
                display: flex;
                // justify-content: center;
                margin-top: 20px;
                // display: none;
                text-transform: uppercase;
                @media (min-width: 1000px) and  (max-width: 1050px){
                    margin-bottom: 10px;
                  }
                @media (max-width: 768px) {
                    display: flex;
                    justify-content: center;
            }
                .btn-ghost{
                    background-color: #f3c465;
                }
            }
            .explore-course-btn-container {
                display: flex;
                // justify-content: center;
                margin-top: 20px;
                @media (max-width: 768px) {
                    display: flex;
                    justify-content: center;
            }
                .explore-course-btn {
                    text-transform: uppercase;
                    background-color: #f3c465;

                }
            }

            .img-box-right{
                width:100%;
                min-height: 567px;
                background-image: url('../../assets/imgs/meeting_1.png');
                background-repeat: no-repeat;
                @media (max-width: 768px) {
                    background-size: contain;
                    background-repeat: no-repeat;
                    min-height: 250px;
                }
            }
            .img-box-left{
                width:100%;
                min-height: 567px;
                background-image: url('../../assets/imgs/meeting_2.png');
                background-repeat: no-repeat;
                @media (max-width: 768px) {
                    background-size: contain;
                    background-repeat: no-repeat;
                    min-height: 250px;
                }
            }

            .m_l20{
                margin-left:20px;
                @media (max-width: 768px) {
                    margin-left:0px;
                }
            }

           
        }
    }
}

.p_r0{
    padding-right:0;
    @media (max-width: 768px) {
        padding-right:15px;
    }
}
.p_l0{
    padding-left:0;
    @media (max-width: 768px) {
        padding-left:15px;
    }
}