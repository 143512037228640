.modal-main-container {
    width: 100vw;
    height: 100vh;
    background-color: #1915158f;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2000;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;

    .modal-inner {
        position: relative;
        border-radius: 5px;
        max-height: 641px;
        height: 100%;
        @media (max-width: 576px) {
            overflow: auto;
            width: 100%;
            height: 100%;
        }
        .close-modal-btn {
            position: absolute;
            font-size: 20px;
            top: 10px;
            right: 10px;
            color: #0c0c0c;
            padding: 5px 10px;
            cursor: pointer;
        }
        .close-modal-forgot{
            z-index: 9;
            top: 35%;
            left: 165px;
            
            @media (max-width: '576px') {
                left: 325px;
                top: 36%;
            }
            @media  (max-width: '400px'){
                top: 36%;
                left: 310px;
            }
            @media  (max-width: '360px'){
                top: 36%;
            }
            @media (min-width: '600px') and (max-width: '699px') {
                top: 41%;
            }
            @media (min-width: '700px') and (max-width: '800px') {
                top: 36%;
            }
            @media (width: '812px') {
                top: 41%;
            }
            @media (width: '736px') {
                top: 41%;
            }
            @media (width: '731px') {
                top: 41%;
            }
            @media (width: '823px') {
                top: 41%;
            }
            @media (min-width: '1000px') and (max-width: '1050px'){
                top: 36%;
            }
            @media (min-width: '1000px') and (min-height: '1300px'){
                top: 43%;
            }
            @media (min-width: '1300px') and (max-width: '1350px'){
                top: 33%;
            }
            @media (min-width: '1351px') and (max-width: '1400px'){
                top: 36%;
            }
            @media (width: '1366px') and (height: '1024px'){
                top: 36%;
            }
            @media (min-width: '1500px') and (max-width: '1550px'){
                top: 36%;
            }
            @media (min-width: '1600px') {
                top: 36%;
            }
            @media (min-width: '1900px') {
                top: 36%;
            }
        }
        // width: 500px;
        // height: 500px;
        background-color: #fff;
        position: relative;
        .text-area {
            margin: 20px;
            background-color: #434a4c;
            height: 100%;
            margin: 0;
            .modal-heading,
            .modal-para {
                color: #fff;
            }
            .hline {
                width: 44%;
                margin-left: 0px;
                border: 4px solid green;
                border-radius: 20px;
            }
        }
        .login-icon-btn {
            width: 91%;
            margin: 0px auto;
            align-items: center;
            display: flex;
            justify-content: space-around;
            border-radius: 5px;
            border: 2px solid #8a8a8a;
            // text-transform: uppercase;
            font-size: 14px;
            padding: 4px 0;
            font-weight: 600;
            background-color: #fff;
            .linkedin-logo {
                padding-top:2px
            }
            .linkedin-text {
                font-weight: 600;
        }
            
        }
    }
    .video_ {
        width: 800px;
        padding: 40px;
        @media (max-width: '800px') {
            width: 601px;
        }
        @media (max-width: '824px') {
            width: 80%;
            margin: auto;
        }
    }

    .modal-inner > div {
        border-radius: 5px;
    }
    .video-modal.modal-inner {
        height: unset;
    }

    .contact-form-modal.modal-inner {
        max-height: unset;
        width: 800px;
        height: unset;
        @media (min-width: '600px') {
            margin-top: 10%;
        }
    }
    .error-form-modal.modal-inner {
        max-height: unset;
        height: unset;
        margin-bottom: 10%;
    }
    .error-form-modal{
        .details-modal-title {
            padding: 1.5em 2em;
            pointer-events: all;
            position: relative;
            width: calc(100% - 4.5em);
            display: flex;
            justify-content: center;
            align-items: center;
        
            h1 {
              font-size: 1.25rem;
              font-weight: 600;
              line-height: normal;
              color: #BE1033;
            }
          }
          .details-modal-content {
            border-top: 1px solid #e0e0e0;
            padding: 2em;
            pointer-events: all;
            overflow: auto;
          }
          .checkout-btn-container{
              display: flex;
              justify-content: center;
              align-items: center;
              margin-bottom: 20px;
          }
    }
}
