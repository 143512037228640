.cart-quantity-container {
    display: flex;
    // width: 100%;
    @media (max-width: 1200px) {
        width: 50%;
        flex-direction: column;
        align-items: baseline;
        // background-color: #f3c565;
    }
    

    .remove-cart-btn {
        padding: 5px 22px;
    }

    .add-to-cart-btn {
        // width: 90%;
        // width: 100%;
        min-width: 110px;
        border: none;
        height: 38px;
        background-color: #f3c465;
        font-weight: 600;
        padding: 0px 20px;
        font-size: 12px;
        @media (max-width: 768) {
            min-width: auto;
        }
         @media (max-width: 576px) {
            border-radius: 5px;
            // width: 80%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .br_radius{
        border-radius:6px;
    }

    .remove-cart-btn {
        // min-width: 110px;
        width: 100%;
        background-color: #1b1b1b;
        color: #f3c465;
        font-size: 12px;
        border: 1px solid #1b1b1b;
        @media (max-width: 576px) {
            border-radius: 5px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    // @media (max-width: 992px) {
    //     .add-to-cart-btn {
    //         font-size: 10px;
    //         padding: 4px 6px;
    //     }
    //     .remove-cart-btn {
    //         font-size: 10px;
    //         padding: 4px 9px;
    //     }
    // }
}

.quantity-changer {
    background-color: #fff;
    @media (min-width: 1200px) {
        margin-left: 15px;
    }
    // border: 1px solid #e8e8e8;
    display: inline-block;
    height: 35px;
    overflow: hidden;
    padding: 0;
    position: relative;
    width: 75px;
    border-radius: 3px;
    .qtybutton {
        color: #fff;
        background-color: #047FB8;
        cursor: pointer;
        float: inherit;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        margin: 0;
        position: absolute;
        text-align: center;
        transition: all 0.3s ease 0s;
        width: 24px;
        border: none;
        border-radius: 5px;
    }
    .dec.qtybutton {
        height: 35px;
        left: 0;
        top: 0;
    }
    .inc.qtybutton {
        height: 35px;
        right: 0;
        top: 0;
    }
    input.cart-plus-minus-box {
        background: transparent none repeat scroll 0 0;
        border: medium none;
        color: #8f8f8f;
        float: left;
        font-size: 14px;
        height: 32px;
        margin: 0;
        padding: 0;
        text-align: center;
        width: 72px;
    }
}

.save-later-text {
    padding: 20px 25px;
}
