.testimonials-container {
    margin-top: 60px;
.testimonials-card{
    @media (max-width: 768px) {
        margin-top: 25px;
    }
    
    .user-card {
        display: flex;
        flex-direction: column;
        // justify-content: space-between;
        box-shadow:0 2px 16px 4px rgba(40, 44, 63, 0.09);
        border-radius: 6px;
        text-align: center;
        padding: 20px;
        min-height: 330px;
        background-color: #ffff;
        width: 95%;

        .quote{
            position: absolute;
            top: -16px;
            background: #13984b;
            width:36px;
            height:32px;
            left: 38px;
            border-radius: 5px;
            .fa-quote-right{
                color: #fff;
                font-size: 12px;
                padding-top:1em;
            }
        }

        .card-upper-half {
            .user-img {
                width: 60px;
                background: #fff;
            }
            .testimonial {
                line-height: 20px;
                color: #494949;
                font-style: italic;
                margin-top: 25px;
                font-size: 12px;
                font-weight: 450;
                @media (max-width: '1200px') and (min-width: '991px') {
                    font-size: 9px;
                }
            }
        }
        .user-lower-half {
            margin-top: 20px;
            text-align: center;
            position: absolute;
            bottom: 0;
            right: 10px;
            width: 100%;
            
            .user-name {
                font-weight: 600;
                font-size: 14px;
            }
            .designation {
                margin-bottom: 10px;
                color: #909090;
                font-size: 12px;
                font-weight: 500;
                font-style: italic;
                .clientName{
                    color: #F19D39;
                }
            }
        }
    }
}
    
}
