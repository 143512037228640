.auth-common-container {
        background-color: #fff;
        // height: 100%;
        display: flex;
        max-width: 720px;
        height: 641px;

        .intro-half {
                display: flex;
                flex-direction: column;
                justify-content: center;

                background-color: #42494b;
                width: 50%;
                // height: 100%;
                padding: 20px;
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
                @media (max-width: 576px) {
                       display: none;
                    }
                .heading {
                        color: #fff;
                        font-size: 20px;
                        line-height: 25px;
                        font-weight: 600;
                }

                .divider {
                        background-color: #01a84e;
                        height: 5px;
                        border-radius: 34px;
                        width: 130px;
                        background-color: #01a84e;
                        margin-top: 15px;
                }
                .para {
                        color: #fff;
                        margin-top: 15px;
                        line-height: 21px;
                        font-size: 16px;
                        a{
                                color: #f3c565; 
                                &:hover{
                                        color: #f3c565;
                                }
                        }
                }
        }

        .form-half {
                min-width: 328px;
                width: 60%;
                @media (max-width: 576px) {
                        width: 100%;
                     }
                .linkedin-login{
                        margin-top: 50px;
                        .fa-linkedin-square{
                                font-size:30px;
                                color: #0e76a8;
                        }
                }
                .horizontal-center{
                        display: flex;
                        align-items: center;
                        font-weight: 600;
                        .horizontal-line-left{
                                width: 38%;
                                background-color: #adadad;
                                height: 1.5px;
                                border: 0 none;
                                margin-right: 10px;
                        }
                        .horizontal-line-right{
                                width: 38%;
                                color: black;
                                margin-left: 10px;
                                background-color: #adadad;
                                height: 1.5px;
                                border: 0 none;
                        }
                }
                .login-horizontal-center{
                        display: flex;
                        align-items: center;
                        font-weight: 600;
                        margin: 10px 0px;;
                        .horizontal-line-left{
                                width: 40%;
                                background-color: #adadad;
                                height: 1.5px;
                                border: 0 none;
                                margin-right: 10px;
                        }
                        .horizontal-line-right{
                                width: 40%;
                                color: black;
                                margin-left: 10px;
                                background-color: #adadad;
                                height: 1.5px;
                                border: 0 none;
                        }
                }
                .flex-inner .flex-outer{
                        list-style-type: none;
                        padding: 0;
                }
                .flex-outer{
                        margin: 0 auto;
                        max-width: 91%;
                        display: flex;
                        justify-content: space-between;
                        input{
                                border-radius: 3px;
                                height: 40px;
                                box-shadow: none;
                                padding-left: 10px;
                                font-size: 14px;
                                color: #333;
                                margin-bottom: 5px;
                        }
                }
                .flex-outer-error{
                        display: flex;
                        justify-content: space-around;
                }
                .flex-inner{
                        margin: 0 auto;
                        max-width: 91%;
                        // li{
                        //         margin-bottom: 35px;  
                        // }
                        .supplier-check-container{
                                display: flex;
                                align-items: center;
                                .supplier-check-input{
                                        height: 15px;
                                        width: 15px;
                                        margin-right: 10px;
                                        margin-top: 8px;
                                        
                                }
                               
                                .supplier-check-text{
                                        margin: 10px;
                                }
                                input[disabled]{
                                        outline:1px solid blue!important; // or whatever
                                      }
                                .supplier-check-input:checked {
                                        background: blue!important;
                                      }
                                      .supplier-check-input:checked {
                                        background: blue!important;
                                        border: 1px solid #d4d4d5!important;
                                      }
                        }
                        
                        .user-check-container{
                                display: flex;
                                align-items: center;
                                .user-check-input{
                                        width: 5%;
                                }
                                .user-check-text{
                                        margin-top: 10px;
                                        margin-left: 10px;
                                }
                        }
                        .password-container{
                                        width: 100%;
                                        .MuiInputBase-root{
                                                :hover .MuiInputBase-root{
                                                        border-color: #e6e6e6!important;
                                                      }
                                        }
                                        .MuiFormControl-root{
                                                width: 100%;
                                                border-color: #e8e8e8;
                                        }
                                      label {
                                        color: RGB(141 141 141 / 54%);
                                       }
                                      label.Mui-focused {
                                        margin-top: -3px;
                                      }
                                      label.MuiFormLabel-filled {
                                        margin-top : -3px;
                                      }
                                      .MuiInputLabel-outlined {
                                          font-Size: 14px;
                                          margin-top: -6px;
                                      }
                                     .MuiOutlinedInput-root {
                                          height: 40px;
                                          font-size: 14px;
                                          border-color: #e6e6e6;
                                          padding-left: 0px;
                                       fieldset {
                                           font-size: 14px;
                                        }
                                        &:hover fieldset {
                                          border-color: #e6e6e6;
                                        }
                                        .Mui-focused fieldset {
                                          border-color: #e6e6e6;
                                        }
                                        &:hover {
                                                border-color: #e6e6e6;
                                              }
                                        
                        }}
                        .btn-green{
                                margin-left: auto;
                                padding: 8px 16px;
                                border: none;
                                background: #50a950;
                                color: #f2f2f2;
                                text-transform: uppercase;
                                letter-spacing: .09em;
                                border-radius: 5px;
                                width: 100%;
                                margin-bottom: 13px;
                        }
                        .no-mandate{
                                display: flex;
                                margin-bottom: 26px;
                        }

                       .forgot-password-link {
                        color: #0D1362;
                } 
                }
                .flex-inner  input {
                        margin-bottom: 5px;
                      }
                .member-chk{
                        display: flex;
                        justify-content: center;
                        margin-top: 12px;
                        p{
                                font-weight: 600;
                        }
                        span{
                                color: #2c317a;
                        }
                }
                .signup-form{
                        padding-left: 15px;
                        p{
                                display: flex;
                                justify-content: center;
                                margin-bottom: 18px;
                                button{
                                        background: none!important;
                                        border: none;
                                        padding: 0!important;
                                        color: #0D1362;;
                                        cursor: pointer;
                                        margin-left: 7px;
                                }
                        }
                        .member-redirect{
                                margin-top: 20px;
                                a{
                                        padding-left: 5px;
                                }
                                buttn{
                                        color: #0D1362;
                                }
                        }
                }
        }

        .inner-container {
                .login-form,
                .signup-form,
                .forgot-password-form {
                        font-weight: 400;
                        margin:20px;
                        width:auto;
                }

                .main-heading {
                        font-weight: 400;
                        font-size: 20px;
                        font-weight: 500;
                }
                .showpassword {
                        .fa-eye {
                                display: block;
                        }
                        .fa-eye-slash {
                                display: none;
                        }
                }
                .password-container {
                        display: inline-flex;
                        width: 100%;
                }
                .hidepassword {
                        .fa-eye {
                                display: none;
                        }
                        .fa-eye-slash {
                                display: block;
                        }
                }

                .input-common {
                        margin-top: 15px;
                        position: relative;
                }

                .forgot-password-link {
                        color: #0D1362;
                        text-align: right;
                        display: block;
                        text-decoration: underline;
                }
                .signup-link,
                .login-link {
                        margin-top: 20px;
                        color: #13984b;
                        font-weight: 600;
                        
                }
                .login-btn,
                .sign-up-btn {
                        margin: 20px 0;
                }
                .partner-dropdown {
                        height: 60px;
                        width: 360px;
                        height: 40px;
                        border-radius: 3px;
                        border: 1px solid #e6e6e6;
                        font-weight: 200;
                        margin-top: 20px;
                        option {
                                font-weight: 200;
                        }
                }
                .supplier-check-container {
                        display: flex;
                        align-items: center;
                        margin-top: 10px;
                        .supplier-check-input {
                                height: 15px;
                                width: 15px;
                                margin-right: 10px;
                        }
                        .supplier-check-text {
                                color: #686868;
                                cursor: pointer;
                        }
                }
        }
}


.auth-common-container .form-half .signup-form .warn-msg {
        margin-bottom: 0;
        font-size: 11px;
        justify-content: initial;
}

.auth-common-container .form-half .signup-form .error-msg {
        color: red;
        font-size: 11px;
        justify-content: initial;
}
