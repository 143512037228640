.tab_holder{
    margin:60px 2px;
}
.feature-background-1 {
    background-image: url(https://assets.contentenablers.com/website/c20/v01/imgs/feature-background-1.jpg);
}
.feature-background-2 {
    background-image: url(https://assets.contentenablers.com/website/c20/v01/imgs/feature-background-2.jpg);
}
.feature-background-3 {
    background-image: url(https://assets.contentenablers.com/website/c20/v01/imgs/feature-background-3.jpg);
}
.sec_bg{
    padding: 50px 0;
    min-height:700px;
    background-size: cover;
    background-position: top;
    background-color: #121D21;
    display: flex;
    padding: 0;
    align-items: center;
    background-repeat: no-repeat;
    margin-top: -2px;
    .main-heading{
        color: #fff;
        margin-bottom: 30px;
        font-size: 32px;
        text-shadow: 0px 5px 12px rgba(0, 0, 0, 0.34);
        text-transform: none;
        letter-spacing: 0;
        font-weight: 600;
    }

    .tabs{
        display:flex;
        justify-content:flex-start;
        align-items:center;
        li{
            @media(max-width: '768px'){
               svg {
                    width: 100%;
                }
            }
            cursor: pointer;
            margin-left:-8px;
        }

     

      
       
    }
    .tab-content{
            min-height:400px;
            .active{
                display:block;
            }

            .wheel-row{
                h3{
                    color: #00A84F;
                    font-weight: 600;
                    font-size: 28px;
                    text-align:left;
                    line-height: 35px;
                    margin-bottom: 30px;
                }
                
                .partner-logo{
                    width: 175px;
                    margin: 20px 0;
                }

                .prac-course-list {
                    li {
                        margin-bottom: 5px;
                    }
                }

                .prac-course-list,.prac-course-para{
                    color:#fff;
                    margin-top:10px;

                    a {
                        color: #fff;
                    }
                }
                p{
                    color:#fff;
                    font-size: 1rem;
                }
                ul{
                    color:#fff;
                    li {
                        list-style-type:circle;
                        margin-left: 20px;
                        font-size: 1rem;
                    }
                }
                .button-container{
                    margin: 40px 0;
                    .sub-text-info{
                        color:#fff;
                        font-weight: 600;
                    }
                    .pri-button{
                        border: 2px solid #F19D39;
                        color: #fff;
                        margin-top: 20px;
                        border-radius: 6px;
                        font-size: 16px;
                        padding: 12px;
                        background-color: transparent;
                    }
                }   
            }
        
    }


    .vertical-line {
        width: 0px;
        position: relative;
        border-left:3px dashed #fff;
        opacity: 0.7;
    }

    #online-training-line{
        .vertical-line {
            height:70px;
            margin: 0 auto;
            margin-left:85px;
            margin-top: 8px;
        }
    }

    #academic-certification-line{
            .vertical-line-2 {
            height: 40px;
            margin-top: -3px;
            margin-left: 30px;
            }
            .vertical-line-1 {
            height: 40px;
            margin: 0 auto;
            margin-top: 8px;
            @media (min-width: 1800px) {
                margin-right: 43em;
            }
            }
            .horizontal-line {
                height: 2px;
                position: relative;
                border-bottom:3px dashed #fff;
                opacity: 0.7;
                width:284px;
                margin-top: -4px;
                margin-left: 34px;
                @media (max-width: 820px) {
                    width:305px;
                }
                @media (max-width: 756px) {
                    width:220px;
                }
                @media (max-width: 576px) {
                    width:157px;
                }
                @media (max-width: 400px) {
                    width:133px;
                }
                @media (max-width: 1200px) and (min-width: 1000px) {
                    width:225px;
                }
            }
    }
    
    #continuous-learning-line {
        .vertical-line-2 {
            height: 40px;
            margin-top: -2px;
            margin-left:52px;
            }
            .vertical-line-1 {
            height: 40px;
            margin: 0 auto;
            margin-left:500px;
            margin-top: 8px;
            @media (max-width: 756px) {
                margin-left: 420px;
            }
            @media (max-width: 576px) {
                margin-left: 305px;
                margin-top: 0px;
            }
            }
            .horizontal-line {
                height: 2px;
                position: relative;
                border-bottom:3px dashed #fff;
                opacity: 0.7;
                width: 440px;
                margin-top: -4px;
                margin-left: 57px;
                @media (max-width: 756px) {
                    width:360px;
                }
                @media (max-width: 576px) {
                    width:252px;
                }
            }
    }

    .segment-selected {
        fill: #00a84f!important;
    }

    path {
        &:hover{
            fill: #F19D39;
        transition: all 0.15s ease-in;
        }
        
    }
}
