.contact-form-modal-section {
    a{
      color: #F3C565 !important;
      padding-left:5px
    }
    margin-top:13px;
    @media (max-width: 767px) {
      padding-left: 30px !important;
      padding-right: 30px !important;
    }
    .form-row {
      .input-container {
        display: flex;
        flex-direction: column;
        label {
          font-weight: 200;
        }
        .contact-input {
          height: 60px;
          width: 97%;
          padding: 15px;
          background-color: #fafafa;
          border-radius: 3px;
          border: 1px solid #707070;
          font-weight: 200;
          option{
            font-weight: 200;
          }
        }
      }
      @media (max-width: 992px) {
        .input-container {
          margin-top: 10px;
         
          .contact-input{
            height: 45px;
            padding: 8px;
            font-weight: 200;
          }
       }
      }
    }
    .agreement-container {
      .checkbox-container {
        padding-left: 4px;
        margin-top: 19px;
        display: flex;
        .checkbox {
          height: 30px;
          margin-top: 8px;
          width: 30px;
          margin-bottom: 20px;
          @media (max-width: 767px) {
            width: 60px !important;
          }
        }
        p{
          margin-top: 8px;
        }
        .para-18 {
            color: #000
        }
      }
      .info {
        margin-top: 17px;
        color: #fff;
      }
    }
    .reCaptcha-container {
      display: flex;
      justify-content: flex-end;
      margin-top: 41px;
      .col-md-6{
        div {
          width: 304px;;
          margin: 0 auto 20px auto;
        }
      }

    }
    .submit-form-button{
        display: block;
        margin: 18px auto;
        &:hover {
            border: 1px solid #ccc
        }
      }
  }

  .organisation, .interested {
      padding-top:10px
  }
  .error-msg {
      color: red
  }
  