.recommended-products-container {

  margin-top: 30px;
  .slide-container {
    display: flex;
    justify-content: fle;
    margin-top: 15px;
    @media (min-width: 992px) {
      width: 90%;
      margin: 0 auto;
      justify-content: center;
    }

    @media (max-width: 576px) {
      width: 168vw;
      margin-left: -38vw;
    }

    .slick-slider {
      width: 100%;
    }
    .prev-btn,
    .next-btn {
      @media (max-width: 576px) {
        display: none;
      }

      z-index: 3;
      border: none;
      background-color: transparent;
      background-repeat: no-repeat;
      background-position: center;
      color: rgba(0, 0, 0, 0);
      margin-top: -50px;
      cursor: pointer;
      svg {
        height: 35px;
        width: 35px;
      }
    }
    .next-btn svg {
      transform: rotate(180deg);
    }
  }

  .slick-slider .slick-initialized{
    padding-bottom: 20px;
  }
  .card-layout {
    max-width: 95%;
    margin: 0 auto;
    padding-right: 5px;
  }
}
