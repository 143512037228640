.added-to-cart-container {
    background-color: #fff;
    padding: 20px;
    max-width: 600px;
    .modal-heading {
        font-size: 20px;
        font-weight: 600;
    }
    .modal-subheading {
        font-size: 18px;
        font-weight: 600;
    }
    .added-course-info {
        display: flex;
        align-items: center;
        margin-top: 10px;
        @media (max-width: 576px) {
            display: inline-block;
        }
        .cart-icon{
            @media (max-width: 576px) {
                display: flex;
                justify-content: center;
            }
        }
        .course-info {
            display: flex;
            .fa-check-circle {
                font-size: 25px;
                color: #b9d449;
            }
            .course-thumbnail {
                width: 100px;
                border-radius: 5px;
                margin-left: 20px;
            }
            .course-title {
                margin-left: 20px;
                max-width: 300px;
                font-weight: 600;
                line-height: 20px;
            }
        }
        .cart-link {
            margin-left: 55px;
        }
    }
    .frequently-bought-together-container {
        background-color: #f2f2f2;
        border: 1px solid #cfcfcf;
        padding: 20px;
        margin-top: 20px;
        border-radius: 8px;

        .recommeded-course-container {
            display: flex;
            align-items: center;
            margin-top: 20px;
            .course-thumbnail {
                width: 160px;
                border-radius: 8px;
            }
            .text-container {
                margin-left: 25px;
                .course-title {
                    display: flex;
                    flex-direction: column;
                    font-size: 16px;
                    font-weight: 600;
                    max-width: 330px;
                }
                .course-price {
                    font-weight: 500;
                    font-size: 20px;

                    margin-top: 15px;

                    .subs-period {
                        font-size: 15px;
                        font-weight: 100;
                    }
                }
            }
        }

        .add-all-btn-container {
            display: flex;
            justify-content: center;
            margin-top: 30px;
        }
    }
    .related-topic-container {
        margin-top: 20px;
        h3 {
            font-weight: 600;
        }
        .related-topic-links-container {
            .related-topic-link {
                border: 1px solid #0e1467;
                padding: 2px 10px;
                border-radius: 16px;
                font-size: 11px;
                background-color: transparent;
                color: #0e1467;
                display: inline-block;
                margin-right: 10px;
                margin-top: 10px;
            }
        }
    }
}
