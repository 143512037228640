.filter-sidebar-container {
    @media (max-width: 576px) {
        display: none;
    }
    .collection-selection-container {
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
        padding: 20px;
        min-height: 300px;
        max-height: 500px;
        border-radius: 7px;
        background-color: #fff;
        .collection-btn {
            display: flex;
            align-items: center;
            justify-content: space-between;

            width: 100%;
            text-align: left;
            border: none;
            border-radius: 4px;
            padding: 10px 12px;
            margin-top: 10px;
            color: #221f1f;
            background-color: transparent;
        }
        .collection-btn-active {
            background-color: #13984b;
            box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
            color: #fff;
            .fa {
                color: #E7E7E7;
            }
        }
    }
}

.categories-container {
    padding-bottom: 10px;
   
    p {
        font-size: 10px;
        padding: 0 11px;
    }
    .jrs {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        margin: 0 10px;
        li {
            border-radius: 12px;
            border: 1px solid #13984b;
            margin-right: 5px;
            margin-top: 7px;
            min-width: 60px;
            max-width: 200px;
            padding: 0 11px;
            text-align: center;
            cursor: pointer;
            font-weight: 200;
        }
        .active {
            background-color: #13984b;
            color: #fff;
        }
    }
}

.filter-sidebar-container-mobile {
    margin: 0 -15px;
    z-index: 10;
    @media (min-width: 576px) {
        display: none;
    }

    .selected-collection {
        background-color: #13984b;
        padding: 13px 16px;
        display: flex;
        justify-content: space-between;
        .fa {
            color: #fff;
            font-size: 24px;
        }
        p {
            color: #fff;
        }
    }

    .collection-selection-container {
        display: flex;
        flex-direction: column;
        background-color: #fff;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        margin: 0;
        .collection-btn {
            display: none;
            padding: 8px 0;
            border: none;
            text-align: left;
            background-color: transparent;
            i {
                display: none;
            }
        }

        .collection-btn-active {
            color: #13984b;
        }
    }

    .collection-dropdown-expanded {
        // height: auto;
        margin: 0 15px;
        // padding: 0 10px 15px;
        padding: 4px 11px 11px;

        .collection-btn {
            display: block !important;
        }
    }
}
