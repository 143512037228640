.header {
    height: 71px;
    position: fixed;
    top: -1px;
    width: 100%;
    z-index: 10;
    background-color: #fff;
    border-bottom: 1px solid #ebebeb;
    // box-shadow: 0 8px 7px -6px rgba(0, 0, 0, 0.05);
    box-shadow: -2px 5px 12px -5px rgba(0, 0, 0, 0.3);
    @media (max-width: 768px) {
        position: absolute;
    }
    .fa-bars {
        @media only screen and (min-width: 576px) {
            display: none;
        }
    }
    .sign-up-link {
        border-radius: 3px;
        padding: 10px 13px;
        margin: auto 0;
        background-color: #13984b;
        color: #fff;
        margin-left: 20px;
        box-shadow: -2px 5px 15px -5px rgba(0, 0, 0, 0.35);
    }

    .header-row {
        display: flex;
        justify-content: space-between;
        height:75px;
        align-items: center;
        width: 100%;
        padding-left: 46px;
        padding-right: 46px;
        @media (max-width: 576px) {
            padding: 0 15px;
        }
        .logo-course-dropdown-container {
            display: flex;
            align-items: center;
            .partner-logo {
                display: block;
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                // margin-left: 15px;
                width: 120px;
                height: 65px;
            }

            .tco-logo {
                background-image: url("../../assets/imgs/TCC-logo.svg");
                width: 297px;
                height: 74px;
                @media only screen and (max-width: 1366px) {
                    width: 225px;
                }
                @media only screen and (max-width: 576px) {
                    width: 170px;
                }
                @media (max-width: 320px) {
                    width: 117px;
                }
            }
            .egadd-logo {
                background-image: url("../../assets/imgs/partner-logos/egadd-logo.jpg");
            }
            .techuk-logo {
                background-image: url("../../assets/imgs/partner-logos/techuk-logo.jpg");
            }
            .abcd-logo {
                background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAeFBMVEX///8AAADJycnc3Nxqamr6+vp6enrs7OxgYGDi4uLOzs6ioqKxsbGAgIDl5eWIiIhJSUnz8/OcnJxzc3MXFxe6urrW1tZbW1tubm7Ly8upqakyMjK7u7uVlZVnZ2dCQkIdHR1TU1MqKio3NzcQEBCNjY0iIiJGRkaCvh95AAAELElEQVR4nO3ZiXLaOhiGYYvFYLOavUBISEJ7/3dYy6sk/zJtgul05n1mzhlsCawvkiXZDQIAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA6ML8eDyOhPO7vkWqUtacrMfj8SU89s2zifntaPjgVv+NgVIqFM4r13IiNXM0M+sskqqg53z9Mv1HKZPs8sLFGwlTL41aM7fKrCxxE6YO806jeCw8TRcTqle7TlR27zoML5viIMrLhITyYOlacWmx4BIMS0n/+K7rjc0qcR6vVxwmk/y3ztmRTrgd5aL+dvGWFz69G3Wjtul/x0ZJenLQrKvi+ninnBNB8FL/uXTCH2bZbp8VWtPRE2TdclLqUypxEgYrfS9VR9kdvHSq6HGbT7s6Yc8u/JFFbJmVO6C7b5f9v+cWCQkDazwvhYCpD+M+dH90qIfq5ntN/ktp730EWctf3SIp4cVIeJZv34qUMBjqL92+2toviIt5YZV1pU1KeDBC/XTvM4eYMOjriIlQvSPX4gYcurNkICdM65+Kj7E8RmtywiBMTy++1toviKpJdNacAYSEQ6N1MzlAzZNwdGdwP9a6upi+7swuFBIejL/D3YZ6Egb7J64YiXHXj5W7dWsm1AHLzc8o2xC08SU8iqtvN27GTa9X75VVau9p5r1se1f1c9yo7/Il7DeHS2es9fvkjjphV/mzbvE0PZy2/rov4f3efxg9XKLq6Ow2WUh4iK0vb1t/3pdQmrc74kz36eGbUyz0YrlqTv+DhGenBRPnOD3cR/VTejzNHwWP9bfb5wtfwrl1c3Tp5PSZ/tv+Mo6F1SLbqOU9p+eL9oc9X8Le3TnqQfTkebbOvNgLlbSnSepVUA/Z1gv4Et7ubRUeZS/eZ8bwkRIa89GHuvM060v4qcSXJg83FwOaWzcxYb3C6M1660OCJ6E+vf9e2/9M6ElYb4rlhJ/lRJgN2La+8CS890jyKHpauUT2G9H+3Fr1vX1YTPXrO7O+nFDvjD6+2fg/4swqBf2sPykPxIR6cK/zjyNj7TCdi22BmFAvo8Y2o0NKfrhTRieKCQ+q3vlkL6bObo1J+W5KSpi9qJq4X+nC1DPPrVS9U5ESrsw/QXDVR/ab1uG46qRmwuhV3V1FH2XjrPYlfXu+F59VY+sRL52BmbX43Zg3jtlsla/nOqHxpjHZLp2prEs95XswCOtmpZ9Os7CyLhbQtVk960X1dutFo3l/WszP5/oi18Mgdy3n6sao7sar8j2fz+sbVF5NnJto0qhwKldU6a1++KR/nNk1bp/atZpkpXyLxluy5GBV2NTPG42Em5envWNbXQZ738V248El76f9wHK4TeW3K8mkHILvi9j+pVq42j73VfejjfR+4V83AgAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAIDgN50yIP2sApPTAAAAAElFTkSuQmCC");
            }
            .ioe-logo {
                background-image: url("../../assets/imgs/partner-logos/ieit-logo.jpg");
            }
            .schar-logo {
                background-image: url("../../assets/imgs/partner-logos/schar-logo.jpg");
            }

            .ce-logo {
                background-image: url("../../assets/imgs/CE_logo.svg");
                width: 200px;
                @media only screen and (max-width: 576px) {
                    width: 170px;
                    margin-left:10px;
                }
                height: 74px;
            }

            .livingston-logo {
                background-image: url("../../assets/imgs/partner-logos/livingston-logo.jpg");
                width: 155px;
                @media only screen and (max-width: 576px) {
                    width: 120px;
                }
            }

            .sgs-logo {
                background-image: url("../../assets/imgs/partner-logos/sgs-logo.jpg");
                width: 95px;
                @media only screen and (max-width: 576px) {
                    width: 75px;
                }
            }
            .zte-logo {
                background-image: url("../../assets/imgs/partner-logos/United_technologies_logo.svg");
                width: 95px;
                @media only screen and (max-width: 576px) {
                    width: 75px;
                }
            }

            .raytheon-logo {
                background-image: url("https://assets.contentenablers.com/storefront/imgs/Raytheon.png");
            }
            .cw-logo {
                background-image: url("../../assets/imgs/partner-logos/cw_logo.png");
            }
            .dell-logo {
                background-image: url("../../assets/imgs/partner-logos/Dell_logo.png");
            }

            .moog-logo {
                background-image: url("https://logos-download.com/wp-content/uploads/2016/12/Moog_logo_logotype.png");
            }
            // .shop-logo{
            //     background-image: url("https://contentenablers.com/static/imgs/CE_logo.svg");
            //     width: 222px;
            // }
            .shop-search {
                .search-input {
                    border-radius: 3px;
                }
            }
           
        }

        .nav-links {
            display: flex;
            align-items: center;
            transition: all 0.4s ease 0s;
            .contact-us{
                font-size: 16px;
                font-weight: 500;
                margin-right: 10px;
            }
            .currency-convertor {
                position: relative;
                display: flex;
                margin: 0px 15px;
                height: 40px;
                width: 115px;
                box-shadow: 0 0 5px -2px rgba(0, 0, 0, 0.35);
                border-radius: 4px;
                font-weight: 600;
                @media (max-width: 576px) {
                    display: none;
                }

                span > img {
                    position: absolute;
                    // padding-left: 15px;
                    z-index: 99;
                    bottom: 7px;
                    bottom:8px;
                    left:10px;
                    width:33px;
                    height: 23px;
                }
                .css-1uccc91-singleValue {
                    & > div {
                        margin-left: -2px;
                    } 
                    @media (max-width: '575px') {
                        left: 35px;
                    }
                }
            }
            .currency-convertor > div  {
                width: 160px;
            }
            .currency-convertor > span{
                margin: auto;
            }

            .currency-convertor > div > div {
                border: none;
            }
            option {
                background-color: red;
                background-image: url("../../assets/imgs/partner-logos/livingston-logo.jpg");
            }

            .cart-icon {
                transition: none !important;
                min-width: 47px;
                height: 42px;
                text-align: center;
                // border: 1px solid #ebebeb;
                border-radius: 5px;
                background-image: url("../imgs/cart-outline.svg");
                background-repeat: no-repeat;
                background-position: center;
                background-size: 46%;
                box-shadow: 0 0 5px -2px rgba(0, 0, 0, 0.35);

                .cart-link {
                    transition: none !important;
                    font-family: $worksans;

                    background-color: $theme-color;
                    height: 10px;
                    width: 10px;
                    border-radius: 10px;
                    font-size: 12px;
                    padding: 2px 6px;
                    position: relative;
                    top: -10px;
                    font-weight: 500;
                    right: -20px;
                }
            }

            .cart-icon:hover {
                transition: none !important;
                .cart-link {
                    transition: none !important;
                    color: $black;
                }
            }

            .search-btn {
                margin-right: 20px;
                display: none;
                @media only screen and (max-width: 992px) {
                    display: block;
                }
            }

            .fa-shopping-cart:before {
                margin-right: 5px;
            }

            .link {
                margin: 0 15px;
                display: block;
                margin-right: 0;
                @media only screen and (max-width: 576px) {
                    display: none;
                }
            }

            .user-initials,
            .user-initials-mobile {
                background-color: #f2f2f2;
                border-radius: 50%;
                padding: 10px;
                margin-left: 15px;
                width: 44px;
                height: 44px;
                text-align: center;
                text-transform: uppercase;
            }

            .user-initials-mobile {
                display: none;
            }

            @media only screen and (max-width: 576px) {
                .user-initials {
                    display: none;
                }
                .user-initials-mobile {
                    display: block;
                }
            }

            .menu-container {
                position: relative;
                height: 100px;
                display: flex;
                margin-left: 9px;
                @media only screen and (max-width: 576px) {
                    display: none;
                }
                .menu-dropdown {
                    display: none;
                    padding: 8px 27px 13px;
                    border-radius: 2px;
                    position: absolute;
                    top: 84px;
                    right: 0;
                    background-color: #fff;
                    font-size: 14px;
                    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
                    .menu-link {
                        display: block;
                        margin-top: 2px;
                    }
                }
            }
            .menu-container:hover {
                .menu-dropdown {
                    display: block;
                }
                .menu-btn::after {
                    background-image: url("../../assets/imgs/chevron-up-green.svg");
                }
            }

            .menu-btn {
                border: none;
                background-color: transparent;
                color: #707070;
                cursor: pointer;
            }
            .menu-btn::after {
                background-image: url("../../assets/imgs/chevron-down-grey.svg");
                content: "";
                height: 10px;
                width: 10px;
                background-repeat: no-repeat;
                background-size: contain;
                margin-left: 5px;
                display: inline-block;
                background-position: center;
            }
        }
    }

    .bottom-header-menu.signup-login-menu {
        & > span {
            position: relative;
            left: 35px;
            z-index: 99;
            @media (max-width: 576px) {
                left: 45px;
            }
        }
    }

    .currency-convertor-mobile {
        .css-1uccc91-singleValue {
            margin-left: 35px;
            // @media (max-width: '575px') {
            //     margin-left: 50px;
            // }
        }
}

    .bottom-header-menu {
        border-top: 1px solid #ebebeb;
        border-bottom: 1px solid #ebebeb;
        padding: 0 10px;
        background-color: #fff;
    }

    .signup-login-menu {
        background-color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 0;
        .logout-link {
            margin-left: 20px;
            color: #6f6f6f;
        }
        .currency-convertor-mobile {
            margin-right: 20px;
            width: 160px;
        }
    }

    .search-big-header-container {
        width: 385px;
        margin-left: 20px;
        @media (max-width: 992px) {
            display: none;
        }
        @media (max-width: 1024px) {
            width: 260px;
        }
    }

    @media (min-width: 992px) {
        .bottom-header-menu {
            .search-input-container {
                display: block;
            }
        }
    }
}

::-ms-backdrop , .header {
    z-index: 2147483647
}
