.banner-common-container {
    background-color: #f3c465;
    padding: 30px 0;
    position: relative;
    // margin-top: 91px;
    @media (max-width: 1200px) {
        margin-top: 142px;
    }

    .banner-row {
        display: flex;
        max-width: 1200px;
        margin: 0 auto;
        @media (max-width: 768px) {
            flex-direction: column;
        }

        .compliance-2021-logo {
            height: 170px;
            margin-top: 3px;
            border-right: 0.5px solid rgba(0, 0, 0, 0.2);
            padding-right: 10px;
            margin: 0 auto;

            @media (max-width: 768px) {
                height: 129px;
                border-right: none;
            }
        }

        .text-container {
            padding-left: 40px;
            @media (max-width: 768px) {
                text-align: center;
                padding-left: 0;
            }
            .heading {
                font-size: 20px;
                font-weight: 700;

                @media (max-width: 768px) {
                    margin-top: 15px;
                }
            }

            .text,
            .explore-btn {
                margin-top: 15px;
            }
        }
    }

    .close-btn {
        position: absolute;
        top: 22px;
        right: 10px;
        font-size: 20px;
        border: 2px solid $black;
        padding: 5px 7px;
        border-radius: 50%;
        cursor: pointer;
    }
}
.supplier-bg-red{
    background-color: #e52e36;
}
.supplier-bg-blue{
    background-color: #047fb8;
}
.supplier-bg-raytheon{
    background-color: #e32e36;
}
.supplier-banner {
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    transition: height 0.2s ease-in;
    height: 485px;
    margin-top: 40px;
    margin-bottom: 13px;


    @media (max-width: 992px) {
        .video-container {
            margin-top: 50px;
        }
    }

    .banner-row {
        display: flex;
        max-width: 1200px;
        margin: 0 auto;
    }
    // @media (max-width: 1200px) {
    //     margin-top: 213px;
    // }

    @media (max-width: 576px) {
        margin-top: 45px;
    }

    @media (max-width: 428px) {
        margin-top: 45px;
    }

    .heading {
        margin-top: 11px;
        font-size: 26px;
        font-weight: 600;
        color: #fff;
    }
    .text {
        color: #ffffff;
        font-weight: 500;
        margin-top: 10px;
        font-size: 16px;
        line-height: 23px;
    }

    .enterprise-insight-logo {
        width: 200px;
    }

    .chevron {
        position: absolute;
        bottom: 5px;
        left: calc(50% - 30px);
        cursor: pointer;
        width: 60px;
        display: block;
        text-align: center;
    }

    .chevron-row {
        display: flex;
        justify-content: center;
        background-color: #f8f8f9;
        position: absolute;
        bottom: 0;
        width: 100%;
        left: 0;
    }

    .explore-btn {
        margin-top: 20px;
    }
    @media (max-width: 992px) {
        height: auto;
        padding: 50px 0;
    }

    .close-btn {
        cursor: pointer;
        position: absolute;
        top: 20px;
        right: 20px;
        background-color: rgba(255, 255, 255, 0.6);
        padding: 5px 7px;
        border-radius: 3px;
    }
}

.supplier-banner-collapsed {
    display: flex;
    height: 73px;
    transition: height 0.2s ease-out;
    padding: 13px 0 0;
    cursor: pointer;
    overflow: hidden;

    .collapsed-content {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: -17px;
        h4 {
            font-weight: 500;
            font-size: 24px;
            color: #fff;
            @media (max-width: 768px) {
                font-size: 20px;
            }
        }
        .enterprise-insight-logo {
            margin-left: -40px;
            height: 40px;
        }
    }

    .heading {
        font-weight: 600;
        padding: 16px 20px;
    }
    .compliance-2021-logo {
        height: 54px !important;
    }
    .close-btn {
        transform: rotate(44deg);
    }
}
