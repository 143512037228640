.policy-list-area{
    margin-top: 30px;
    div{
        margin: 0 auto;
    }
    a{
        color: #F3C565 !important;
    }
    h3{
        font-size: 20px;
        font-weight: 500;
    }
    ul{
        list-style: disc;
        color: #494949;
        margin-left: 20px;
    }
    li{
        font-weight: 400;
    }
    strong{
        font-weight: 600;
    }
}