.bread-crumb-bar-container {
    position: sticky;
    top: 70px;
    @media (max-width: 576px) {
        position: absolute;
        top:unset !important;
        right: 28px
    }
    @media (max-width: 768px) {
        top:0;
    }
    background-color:#f8f8f9;

    height: 50px;
    // padding-left: 15px;
    padding-left:46px;
    padding-top: 15px;
    z-index: 5;
    display: flex;
    align-items: center;

    width: 100%;
    .breadcrumbs {
        display: flex;
        @media (max-width: 575px) {
            // margin-left: -27px;
            margin-top: -15px;
        }
        a,
        .separator {
            color: #5284B8;
            font-weight: 500;
        }
        .separator {
            margin: 0 5px;
        }
        a:hover {
            text-decoration: underline;
        }
        .link-container {
            display: flex;
            align-items: center;
            a {
                font-weight: lighter;
            }
        }
        .no-link {
            p {
                color: #000;
                font-weight: 600;
                @media (max-width: 768px) {
                    width: 127px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }
}
