.certificate-partner-card {
    // background-color: #f6f6f6;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 16px 4px rgba(40, 44, 63, 0.05);
    @media (max-width: 992px) {
        margin-top: 20px;
    }
    .row {
        background: #f8f8f8;
        margin:-20px;
         b {
             font-size: 12px;
             margin-top: 11px;
             font-weight: 600;
         }
         img {
             padding: 20px;
         }
    }
    .partner-logo {
        width: 100%;
        max-width: 310px;
        background-color: #fff;
        margin: 0 auto;
        display: block;
        padding: 10px 25px;
    }
    .read-more-btn {
        margin: 20px 0;
        border: none;
        color: #13984b;
        box-shadow: none;
        padding-left: 0;
      
        @media (max-width: 992px) {
            display: block;
            margin: 40px auto 0;
            max-width: 265px;
            border-radius: 0;
        }
    }
    .learn-more-btn {
        border: none;
        color: #13984b;
        box-shadow: none;
        padding-left: 0;
        font-size: 15px;
        @media (max-width: 992px) {
            display: block;
            max-width: 265px;
            border-radius: 0;
        }
       
    }
}
