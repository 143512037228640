.courses_dr{
    .active{
        border: 2px solid #13984b;
        border-radius: 5px;
        background-color:#fff;
    }
    width:107px;
    margin-left: 20px;
    border-radius: 5px;
    background-color:#f3c565;
    color:#000;
    cursor: pointer;
    .active{
        .crt{
            color:#13984b;
        }
    }
    
    .drp{
        width: 100%;
        padding: 9px 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    h5{
        font-weight:600;
        color:#000;
    }
    .active{
        h5{
            font-weight:600;
            color:#13984b;
        }
    }
    .ul_holder{
        position: absolute;
        width: 43%;
        background: #fff;
        top: 72px;
        border-radius:7px;
        border: 1px solid #ddd;
        // min-height:340px;
        .info{
            padding: 14px;
        }
        ul{
            width: 50%;
            box-shadow: 0px 0px 20px 0px #ddd;
            li{
                padding: 14px;
                border-bottom: 1px solid #ddd;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .sub_menu{
                    position: absolute;
                    right: 0;
                    display: none;
                    li{
                        color:#000;
                        padding: 14px 11px;
                    }
                }
                &:hover{
                    background-color:#F3C465;
                    // color: #fff;
                    .sub_menu{
                        background-color: #fff;
                        display:block;
                    }
                }
                i {
                    color: #808080
                }
            }
        }
    }
}