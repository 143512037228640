$lgreen:#00A84F;
$grey:#CCCCCC;
$lgrey:#dddddd;
.custom_bg_br{
   input{
      background-color:#0B1659!important;
      border: none!important;
      &+label {
         border:none!important;
      }
      &:not(:checked) {
         background-color:#0B1659!important;
         &:before {
            color: #fff!important;
         }
      }
      &:checked {
         &:after {
            color: #fff!important;
         }
      }
   } 
}
.switcher {
      position: relative;
      width:290px;
      height:35px;
      border-radius:25px;
      margin:0;
      font-size: 16px;
      // margin-bottom: 30px;
      margin-top:10px;
      @media (max-width: 576px) {
         height: auto;
     }
      input {
         width: 325px;
         appearance: none;
         position: relative;
         width:290px;
         height:40px;
         border-radius:25px;
         background-color:$lgreen;
         outline:none;
         cursor: pointer;
         &:before, &:after {
            z-index:2;
            position: absolute;
            top:50%;
            transform:translateY(-50%);

            color:$lgrey;
         }
         &:before {
            content: 'Pay Annually';
            left:20px;
            font-weight: 600;
         }
         &:after {
            content: 'Pay Monthly';
            right:20px;
            font-weight: 600;
         }
      }
      label {
         z-index:1;
         position: absolute;
         top:2px;
         bottom:10px;
         border-radius:20px;
         height: 35px;
      }
      &.switcher-1 {
         input {
            width: 325px;
            transition:.25s -.1s;
            font-size: 16px;
            @media (max-width: 576px) {
               width: 250px;
           }
            &:checked {
               background-color:$lgrey;
               &:before {
                  color:$lgreen;
                  transition: color .5s .2s;
                  // font-weight:700;
               }
               &:after {
                  color:#000;
                  transition: color .5s;
               }
               &+label {
                  left:2px;
                  width: 155px;
                  right:168px;
                  background:#fff;
                  transition: left .5s, right .4s .2s;
                  border:1px solid $lgreen;
                  height: 35px;
                  @media (max-width: 576px) {
                     width: 128px;
                     right: 168px;
                 }
                  
               }
            }
            &:not(:checked) {
               background:#ddd;
               transition: background .5s -.1s;
               &:before {
                  color:#000;
                  transition: color .5s;
               }
               &:after {
                  color:$lgreen;
                  transition: color .5s .2s;
                  // font-weight:700;
               }
               &+label {
                  left:168px;
                  width: 155px;
                  right:2px;
                  background:#fff;
                  transition: left .4s .2s, right .5s, background .35s -.1s;
                  border:1px solid $lgreen;
                  @media (max-width: 576px) {
                     left:125px;
                     width: 124px;
                 }

               }
            }
         }
      }
     
   }

   *::-ms-backdrop, .switcher input::-ms-check {
      display: none;
   }

   *::-ms-backdrop, #switcher-1 {
      z-index: 99999;
      background: transparent;
   }

   *::-ms-backdrop, .annually-switcher, .monthly-switcher {
      position:relative;
      bottom:40px;
      cursor: pointer;
      z-index: 9999
   }

   *::-ms-backdrop, .annually-switcher {
      left: 40px;
   }

   *::-ms-backdrop, .monthly-switcher {
      left:100px;
   }

   *::-ms-backdrop, .custom_bg_br #switcher-1 {
      z-index: 99999;
      background: transparent !important;
   }

   *::-ms-backdrop, .custom_bg_br  .annually-switcher, .monthly-switcher {
      color: #00A84F;
   }

   *::-ms-backdrop, .switcher {
      border: 1px solid #ccc !important;
      background: #dddddd !important;
      height:42px;
      width: 324px;
   }

   *::-ms-backdrop, .custom_bg_br {
      background: #0B1659 !important;
      border: none !important;
   }





 
$lgreen:#00A84F;
$grey:#CCCCCC;
$lgrey:#dddddd;
.custom_bg_br{
   input{
      background-color:#0B1659!important;
      border: none!important;
      &+label {
         border:none!important;
      }
      &:not(:checked) {
         background-color:#0B1659!important;
         &:before {
            color: #fff!important;
         }
      }
      &:checked {
         &:after {
            color: #fff!important;
         }
      }
   } 
}
.switcher {
      position: relative;
      width:290px;
      height:35px;
      border-radius:25px;
      margin:0;
      font-size: 16px;
      // margin-bottom: 30px;
      margin-top:10px;
      @media (max-width: 576px) {
         height: auto;
     }
      input {
         width: 325px;
         appearance: none;
         position: relative;
         width:290px;
         height:40px;
         border-radius:25px;
         background-color:$lgreen;
         outline:none;
         cursor: pointer;
         &:before, &:after {
            z-index:2;
            position: absolute;
            top:50%;
            transform:translateY(-50%);

            color:$lgrey;
         }
         &:before {
            content: 'Pay Annually';
            left:20px;
            font-weight: 600;
         }
         &:after {
            content: 'Pay Monthly';
            right:20px;
            font-weight: 600;
         }
      }
      label {
         z-index:1;
         position: absolute;
         top:2px;
         bottom:10px;
         border-radius:20px;
         height: 35px;
      }
      &.switcher-1 {
         input {
            width: 325px;
            transition:.25s -.1s;
            font-size: 16px;
            @media (max-width: 576px) {
               width: 250px;
           }
            &:checked {
               background-color:$lgrey;
               &:before {
                  color:$lgreen;
                  transition: color .5s .2s;
                  // font-weight:700;
               }
               &:after {
                  color:#000;
                  transition: color .5s;
               }
               &+label {
                  left:2px;
                  width: 155px;
                  right:168px;
                  background:#fff;
                  transition: left .5s, right .4s .2s;
                  border:1px solid $lgreen;
                  height: 35px;
                  @media (max-width: 576px) {
                     width: 128px;
                     right: 168px;
                 }
                  
               }
            }
            &:not(:checked) {
               background:#ddd;
               transition: background .5s -.1s;
               &:before {
                  color:#000;
                  transition: color .5s;
               }
               &:after {
                  color:$lgreen;
                  transition: color .5s .2s;
                  // font-weight:700;
               }
               &+label {
                  left:168px;
                  width: 155px;
                  right:2px;
                  background:#fff;
                  transition: left .4s .2s, right .5s, background .35s -.1s;
                  border:1px solid $lgreen;
                  @media (max-width: 576px) {
                     left:125px;
                     width: 124px;
                 }

               }
            }
         }
      }
     
   }

   *::-ms-backdrop, .switcher input::-ms-check {
      display: none;
   }

   *::-ms-backdrop, #switcher-1 {
      z-index: 99999;
      background: transparent;
   }

   *::-ms-backdrop, .annually-switcher, .monthly-switcher {
      position:relative;
      bottom:40px;
      cursor: pointer;
      z-index: 9999
   }

   *::-ms-backdrop, .annually-switcher {
      left: 40px;
   }

   *::-ms-backdrop, .monthly-switcher {
      left:100px;
   }

   *::-ms-backdrop, .custom_bg_br #switcher-1 {
      z-index: 99999;
      background: transparent !important;
   }

   *::-ms-backdrop, .custom_bg_br  .annually-switcher, .monthly-switcher {
      color: #00A84F;
   }

   *::-ms-backdrop, .switcher {
      border: 1px solid #ccc !important;
      background: #dddddd !important;
      height:42px;
      width: 324px;
   }

   *::-ms-backdrop, .custom_bg_br {
      background: #0B1659 !important;
      border: none !important;
   }