.courses-main-area {
    // margin-top:45px;
    @media (max-width: 768px) {
        margin-top: 45px;
    }

    .banner {
        background-color: #13984b;
        text-align: center;
        margin: -39px 0 37px 0;
        position: fixed;
        width: 100%;
        z-index: 4;
        @media (max-width: 1200px) {
            margin: -18px 0 37px 0;
        }
        padding: 8px;
        color: #fff;
        display: block;
        span:hover {
            text-decoration: underline;
        }
    }

    // .banner-common-container {
    //     margin-top: -39px;
    //     @media (max-width: 1200px) {
    //         margin-top: -18px;
    //     }
    // }

    .course-container {
        // position: relative;
        // top: 20px;
        // max-width: 1200px;
        padding-bottom: 50px;
        margin: 0 auto;
        margin-top: 10px;
        // min-height: 600px;
        .heading-switch-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        .collection-heading {
            font-weight: 500;
            font-size: 20px;
            @media (max-width: 992px) {
                font-size: 15px;
                line-height: 20px;
            }
            @media (max-width: 576px) {
                margin-left: -11px;
            }
        }
        .collection-heading{
            border-bottom: 1px solid #5ea65b;
            padding:6px;
            font-size: 20px;
            // margin: 20px 0px !important;
            margin-bottom: 20px !important;
            padding-top: 0px;
            font-weight: 600;
        }
        .collection-row {
            border-bottom: 1px solid #d8d8d8;
            margin-bottom: 20px;
            margin-top: -10px;
            @media (max-width: 576px) {
                padding-bottom: 20px;
            }
            .collection-container {
                border-bottom: 1px solid #d8d8d8;
                position: relative;
                top: 1px;
                .heading-btn-container {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-top: 30px;
                    @media (max-width: 992px) {
                        margin-top: 15px;
                    }
                    .show-all-btn {
                        border: none;
                        background-color: transparent;
                        font-weight: 500;
                        color: #8f8f8f;
                        text-align: right;

                        @media (max-width: 992px) {
                            font-size: 11px;
                            line-height: 18px;
                            width: 80px;
                            margin-right: -11px;
                        }
                    }
                }
            }
            @media (max-width: 768px) {
                .card-view {
                    padding-bottom: 12px;
                }
            }
            .card-view:nth-child(odd) {
                border-right: 1px solid #d8d8d8;
                @media (max-width: 768px) {
                    border: none;
                    border-bottom: 1px solid #d8d8d8;
                }
            }
        }
        .loading-text {
            text-align: center;
            margin-top: 25%;
        }
        .f_holder{
            display: flex;
            justify-content: space-between;
            align-items: center;
            .ins{
                    color: #b1b1b1;
                    font-size: 12px;
                    span{
                       font-weight: 600;
                    }
            }
        }
    }

    .companion-collection-section {
        margin: 50px 0;
        .collection-heading {
            margin-bottom: 10px;
        }
    }

    .client-section {
        // padding: 50px 0;
        // margin-bottom: -100px;
        .container{
            background: #fff;
            padding-top: 30px;
            .slick-items{
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
            }
        }
    }
}
