.terms-list-area{
    margin-top: 175px;
    .sub-num{
        padding-right: 15px;
    }
    p{
        margin: 25px auto;
    }
}
.detail-list-area{
    margin-top: 45px;
    .sub-num{
        padding-right: 15px;
    }
    p{
        margin: 25px auto;
    }
    a{
        color: #f3c565;
    }
    li{
        margin-left: 25px;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        color: #494949;
    }
    
.certificate-partner-card{
    display: flex;
    flex-direction: column;
    align-items: center;
    .dell-logo {
        background-image: url("../../assets/imgs/partner-logos/Dell_logo.png");
        display: block;
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: contain;
        height: 65px;
        width: 158px;
    }
    .free-supplier-bg{
        background-color: #047fb8;
        padding: 10px;
        width: 158px;
    }
    hr{
        color: #000;
    width: 100%;
    }
}

}