.home-main-container {
    background-color: #fff;

    .hero-section {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 450px;
        min-height: 450px;
        padding: 45px;
        color: #fff;
        max-width: calc(100vw - 80px);
        background-image: url("../../assets/imgs/hero-background-img.jpg");
        background-size: cover;
        background-position: center;
        border-radius:30px;
        margin: 70px auto 0;
        @media (max-width:400px) {
            height: 500px;
        }
        @media (max-width: 576px) {
            border-radius:10px;
        }
        @media (max-width:320px) {
            padding: 0;
        }
        .cus-mr-20{
            margin-left: 20px;
            @media (max-width: 576px) {
                margin-left:0;
            }
        }
        .content {
            // width: 100%;
            .box-item{
                width:576px;
                @media (max-width: 992px) {
                    width:100%;
                }
                @media (max-width:576px) {
                    width:100%;
                }
            }
            .heading {
                font-size: 44px;
                font-weight: 600;
                color: #fff;
                @media (max-width: 992px) {
                    font-size: 36px;
                    line-height: 36px;
                    // padding: 0 20px;
                }

                @media (max-width:576px) {
                    font-size:28px;
                    line-height: 33px;
                    padding:0;
                }
                
                @media (max-width:320px) {
                    font-size:25px;
                    line-height:30px;
                }
            }
            .sub-heading {
                font-size: 21px;
                @media (max-width: 576px) {
                    font-size: 18px;
                }
                font-weight: 400;
                margin-top: 20px;
                margin-bottom: 20px;
                color: #fff;
            }
            .btn-holder {
                display: flex;
                margin-top: 40px;
                a {
                    text-transform: uppercase;
                }
                .sign-up-btn {
                    margin-left: 20px;
                }
            }

            @media (max-width: 576px) {
                .btn-holder {
                    flex-direction: column;
                    .sign-up-btn {
                        // width: 205px;
                        margin-left: 0;
                        margin-top: 15px;
                    }
                    .btn-big {
                        font-size: 13px;
                        padding: 12px 12px;
                    }
                }
            }
        }
    }

    // sections
    .featured-courses-section {
        // padding: 50px 0;
        // .col-md-12 {
        //     overflow: hidden;
        // }
        // .featured-heading {
        //     margin-bottom: 20px;
        // }
        

        background-color:#fff;
        h2{
            padding-top: 40px;
            font-size: 30px;
            font-weight: bold;
            @media (max-width: 576px) {
                font-size:20px;
            }
        }
       
        .courses-main-area {
            margin-top: 22px;
        }

        .explore-course-btn-container {
            display: flex;
            justify-content: center;
            padding-bottom:40px;
            .explore-course-btn {
                text-transform: uppercase;
                background-color:#f3c46557;
                border:1px solid #F3C465;
                .back-btn{
                    padding-left:17px;
                }
            }
        }
    }

    .enhance-your-career-section {
        padding: 50px 0;
        background-color: #f8f8f9;
        .car-title{
            font-size:30px;
            font-weight:bold;
            @media (max-width: 576px) {
                font-size:20px;
             }
        }

      
        .features {
            .card-item {
                text-align: justify;
                width: 85%;
                margin-top: 35px;
                background-color: #fff;
                box-shadow: 0px 3px 10px -6px rgba(0, 0, 0, 0.25);
                min-height: 330px;
                @media (min-width: 1200px) {
                    min-height: 413px;
                }

                @media (max-width: 1200px) {
                    width: 100%;
                }
                border-radius: 8px;
                .card-img {
                    width: 100%;
                }
                .title {
                    font-weight: 600;
                    margin-top: 20px;
                    background-color: rgba(0, 0, 0, 0.6);
                    padding: 10px 15px;
                    margin-top: -43px;
                    color: #fff;
                    font-weight: 100;
                    z-index: 1;
                    font-weight: 100;
                    position: relative;
                }
                .sub-title {
                    font-size: 14px;
                    padding: 0 15px;
                    line-height: 19px;
                    margin-top: 20px;
                    padding-bottom: 10px;
                }
            }
        }
    }

    .testimonial-section {
        border-bottom: 1px solid rgba(40, 44, 63, 0.09);
        box-shadow:0 0px 0px 2px rgba(40, 44, 63, 0.09);
        padding: 50px 0;
        background-image: url("../../assets/imgs/testimonial-bg.jpg");
        background-size: cover;
        background-position: center;
        .testi-head{
            font-size: 30px;
            font-weight: bold;
            @media (max-width: 576px) {
                font-size:20px;
            }
            
        }
    }

   

    .partner-section {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 20px 0;
        .title {
            font-weight:bold;
            margin-top: 15px;
            text-align: center;
            font-size: 30px;
            @media (max-width: 576px) {
                font-size:20px;
            }
        }
        .partner-logos {
            margin: 10px auto 20px;
            display: flex;
            cursor: pointer;
            justify-content: flex-start;
            @media (max-width: 576px) {
                justify-content: center;
            }
            align-items: center;
            flex-wrap: wrap;
            .partner-logo {
                width: 160px;
                margin: 10px 20px;
                background-size: cover;
                box-shadow: 2px 4px 15px -6px rgba(0, 0, 0, 0.25);
                border-radius: 6px;
                padding: 10px 30px;
                @media (max-width: 576px) {
                    width: 117px;
                    margin: 10px;
                }
            }
            .partner-logo:hover {
                transform: scale(1.1);
            }
        }
    }

    .client-section {
        padding: 50px 0;
        // margin-bottom: -111px;
    }
}
