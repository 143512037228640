.card-list-display{
    @media (max-width: 768px) {
        overflow: auto;
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        margin-left: -30px;
    }
}


.user-course-card-container{
    // margin-top: 40px!important;
    .card-box{
        // width: 279px;
        // height:258px;
        .course-text-container{
            margin-bottom: 10x;
        }
        .btn-holder{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin:5px;
            .seats-lbl{
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-direction: row;
                .seat_img{
                    margin-left:3px;
                    margin-right:3px;
                }
                span{
                    font-size:13px;
                }
            }
            .manage-btn{
                background-color: #F3C465;
                border-radius: 5px;
                border: none;
                height: 31px;
                min-width:85px;
                text-transform: uppercase;
                font-weight:600;
                
            }
        }
    } 
}

.course-view-inner-container {
    display: flex;
    position: relative;
    // border-top: 1px solid #ebebeb;
    position: relative;
    @media (max-width: 576px) {
        justify-content: center;
        align-items: center;
    }
    .card-innermost-container {
        background-color: #fff;
        border-radius: 5px;
        .thumbnail {
            max-width: 300px;
        }
        .thumbail-img {
            background-size: cover;
            background-repeat: no-repeat;
            // background-position: center;
            // border-radius: 6px;
        }

        .course-link {
            .title {
                line-height: 20px;
                font-weight:600;
                cursor: pointer;
                @media (max-width: 320px) {
                    text-align: center;
                }
            }
            .cpd {
                font-weight: 500;
                span {
                    font-weight: 600;
                }
            }
            .sub-title {
                color: #494949;
                font-size: 13px;
                font-weight: 300;
            }
            .price {
                color: #333;
                font-weight: 600;
                @media (max-width: 992px) {
                    font-size: 13px;
                }
            }
        }
        .recharge-price {
            display: flex;
            align-items: center;
            justify-content: center;
            .price-monthly,
            .price-yearly {
                .price-num{
                    font-size: 20px;
                    font-weight: 600;
                    sup{
                        top: -5px;
                        font-weight: 400;
                    }
                    @media (max-width: 576px) {
                        font-size: 14px;
                    }
                }
                .price-text{
                    font-size: 14px;
                    font-weight: 500;
                    @media (max-width: 576px) {
                        font-size: 11px;
                    }
                }
                color: #333;
                // font-weight: 600;
                // font-size: 13px;
                line-height: 17px;
                // max-width: 40%;
                @media (max-width: 992px) {
                    margin: 10px;
                    font-size: 13px;
                }
            }
            .price-monthly {
                margin-top:0px;
                text-align: right;
                margin-right: 15px;
            }
        }
        
        .add-to-cart-btn-container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-weight: 500;
            border-top: 1px solid #d8d8d8;
            // margin: 15px -10px -10px;
            // border-top: 1px solid #d8d8d8;
            @media (max-width: 576px) {
                flex-direction: row;
                // border-top: none;
                align-items: end;
            }
            .learn-more-link {
                margin: 0px auto;
                border: none;
                background-color: transparent;
            }
            .learn-more-link:hover {
                color: $theme-color;
            }
        }
        &:hover {
            background-color: #f1f1f1;
        }
        .add-to-cart-btn-container-list{
            min-width: 200px;
            border: none;
            flex-direction: row;
            .add-to-cart-btn{
                border-radius: 6px;
            }
            .cart-quantity-container{
                width: auto;
            }
        }
    }
    &:hover {
        @media (min-width: 992px) {
            // box-shadow: 0 2px 16px 4px rgba(40, 44, 63, 0.09);
            // .thumbail-img {
            transform: scale(1.02);
            transition: all 0.3s ease-in;
            // }
        }
    }
}

.card-layout {
    flex-direction: column;
    font-weight: 400;
    min-height: 300px;
    @media (min-width: 899px) {
      max-width: 273px;
    }
    margin: 10px 0;
    @media (max-width: 576px) {
        min-height: 250px;
        padding: 0 5px;
    }
    .card-innermost-container {
        // padding-bottom: 10px;
        overflow: hidden;
        border: 1px solid #d8d8d8;
        border-radius: 7px;
        transition: all 0.3s ease-out;
        // min-width: 244px;
        display: inline-block;
        @media (max-width: 576px) {
            max-width: 40%;
            min-width: 210px;
            margin-left: 20px;
        }
        // @media (min-width:650px) and( max-width: 900px) {
        //     min-width: 158px;
        // }
        .thumbail-img {
            transition: all 0.3s ease-in;
            // @media (max-width: 992px) {
            //     margin-top: 10px;
            // }
            cursor: pointer;
            @media #{$xs-layout} {
                height: 135px;
            }

            @media #{$sm-layout} {
                height: 135px;
            }

            @media #{$md-layout} {
                height: 101px;
            }
            @media #{$lg-layout} {
                height: 140px;
            }

            @media #{$xl-layout} {
                height: 150px;
            }

            @media (min-width: 1365px) {
                height: 136px;
            }
        }
        .course-text-container {
            .add-to-cart-btn-container {
                width: 100%;
                .cart-quantity-container {
                    width: 100%;
                }
                .supplier-list{
                    width: 100%;
                }
            }
            @media (min-width: 1200px) {
                // padding: 0 10px;
            }
            .course-link {
                padding: 0 10px;
                .title {
                    margin-top: 10px;
                    font-size: 14px;
                    min-height: 70px;
                    @media (max-width: 1200px) {
                        font-size: 13px;
                        min-height: 65px;
                        line-height: 16px;
                        font-weight: 500;
                    }
                }
                .price {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-top: 8px;
                    // font-weight: 600;
                    .price-num{
                        font-size: 20px;
                        font-weight: 600;
                        @media (max-width: 576px) {
                            font-size: 14px;
                        }
                        sup{
                            top: -5px;
                            font-weight: 400;
                        }
                    }
                    .price-text{
                        font-size: 14px;
                        font-weight: 500;
                        @media (max-width: 576px) {
                            font-size: 11px;
                        }
                    }
                    @media (max-width: 320px) {
                        text-align: center;
                    }
                }
            }

            .add-to-cart-btn-container {
                margin-top: 15px;
                // max-width: 170px;
                @media (max-width: 992px) {
                    margin-top: 5px;
                }
                // .subscribe-btn, .remove-cart-subscribe {
                //     width: 100%;
                // }

                .add-to-cart-btn {
                    border: none;
                    background-color: $theme-color;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    // margin-right: 10px;
                }
                
                @media (max-width: 992px) {
                    .add-to-cart-btn {
                        font-size: 12px;
                        padding: 4px 6px;
                        min-width: auto;
                    }

                    .remove-cart-btn {
                        font-size: 10px;
                        padding: 4px 9px;
                    }
                }
                .learn-more-link {
                    // margin-left: 15px;
                    min-width: 110px;
                    // @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                    //     position: relative;
                    //     left: 60px;
                    // }

                    padding: 6px 4px 5px;

                    @media (max-width: 1800px) {
                        font-size: 13px;
                        // padding: 4px 6px;
                    }
                    @media (max-width: 1080px) {
                        font-size: 12px;
                        // padding: 4px 6px;
                    }
                    @media (max-width: 992px) {
                        font-size: 11px;
                        // padding: 0;
                        min-width: 75px;
                    }
                    @media (max-width: 992px) {
                        font-size: 12px;
                        min-width: 60px;
                    }
                }
            }

            @media (max-width: 992px) {
                .subscribe-btn {
                    font-size: 10px !important;
                    padding: 4px 6px !important;
                }
            }
        }

        &:hover {
            @media (min-width: 992px) {
                box-shadow: 0 2px 16px 4px rgba(40, 44, 63, 0.09);
                // .thumbail-img {
                transform: scale(1.02);
                transition: all 0.3s ease-in;
                // }
            }
        }
    }
}

.list-layout {
    width: 100%;
    margin: 10px 0 0 16px;
    padding-left: 0;
    padding-right: 0;
    border-radius: 5px;
    border: 1px solid #e6e6e6;
    background-color: #fff;
    @media (max-width: 576px) {
        padding: 20px 3px 20px 15px;
        margin-left: -11px;
    }
    .card-innermost-container {
        box-shadow: 0 2px 16px 4px rgba(40, 44, 63, 0.09);
        transform: scale(1.02);
        transition: all 0.3s ease-in;
        width: 100%;
        .course-link {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            padding-right: 50px;
            @media (max-width: 992px) {
                flex-direction: column;
                align-items: baseline;
                padding-right: 0;
            }
            @media (max-width: 576px) {
                flex-direction: row;
                padding-right: 15px;
                align-items: center;
            }
            .title {
                font-size: 14px;
                font-weight: 500;
                line-height: 23px;
                max-width: 400px;
                @media (max-width: 1200px) {
                    font-size: 13px;
                    min-height: 45px;
                    line-height: 16px;
                }
            }
            .price {
                @media (max-width: 992px) {
                    font-size: 14px;
                }
            }
        }

        .course-text-container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            padding: 15px;
            @media (max-width: 576px) {
                display: block;
            }
        }
        .add-to-cart-btn-container {
            // margin-top: 30px;

            @media (max-width: 992px) {
                flex-direction: column;
                margin-right: -12px;
            }

            .learn-more-link {
                min-width: 100px;
                padding: 0;
                font-size: 12px;
                @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                    // position: relative;
                    // left: 40px;
                }
                @media (max-width: 1200px) {
                    font-size: 12px;
                    padding: 4px 6px;
                    margin-left: 0;
                }
                @media (max-width: 991px) {
                    width:100%;
                }
                @media (max-width: 576px) {
                    font-size: 10px;
                    padding: 4px 2px;
                    // min-width: 85px;
                    margin-left: 18px;
                    display: flex;
                }
            }
        }
        .recharge-price {
            min-width: 200px;
            // @media (max-width: 768px) {
            //     min-width: auto;
            // }
            margin-right: 10px;
            .price-monthly,
            .price-yearly {
                .price-num{
                    font-size: 20px;
                    font-weight: 600;
                    @media (max-width: 576px) {
                        font-size: 14px;
                    }
                }
                @media (max-width: 768px) {
                    font-size: 11px;
                    min-width: 120px;
                }
            }
        }
        &:hover {
            @media (min-width: 992px) {
                box-shadow: 0 2px 16px 4px rgba(40, 44, 63, 0.09);
                // .thumbail-img {
                transform: scale(1.02);
                transition: all 0.3s ease-in;
                // }
            }
        }
    }
}

.list-layout:hover {
    background-color: #eeeeee;
}

.course-list-card-placeholder {
    .text-row {
        width: 100% !important;
    }
}

.partner-logo-rec {
    position: absolute;
    right: 21px;
    z-index: 10;
    top: 143px;
    .partner-logo {
        display: block;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        margin-left: 15px;
        width: 71px;
        height: 42px;
        margin-right: -10px;
        margin-top: -22px;
    }
    .egadd-logo {
        background-image: url("../../assets/imgs/partner-logos/egadd-logo.jpg");
    }
    .techuk-logo {
        background-image: url("../../assets/imgs/partner-logos/techuk-logo.jpg");
    }
    .ioe-logo {
        background-image: url("../../assets/imgs/partner-logos/ieit-logo.jpg");
    }
    .schar-logo {
        background-image: url("../../assets/imgs/partner-logos/schar-logo.jpg");
    }
    .schar-logo {
        background-image: url("../../assets/imgs/partner-logos/schar-logo.jpg");
    }
}

