.crs_tab_holder{
    .MuiAppBar-root{
    border-radius:6px;
    @media (max-width: 576px) {
        margin-top:10px;
    }
    
}
.MuiTabs-root{
        background-color: #fff;
        border-radius:6px;
        padding: 0 15px;
    .MuiTab-root{
        text-transform: initial;
        max-width:400px;
        font-family: "Work Sans", sans-serif;
        color: #8D8D8D;
    }
    .PrivateTabIndicator-root-2 {
        height:4px;
        border-radius:2px;
    }
    .MuiTab-textColorPrimary.Mui-selected {
        color:#13984b
    }
    
    .MuiTabs-indicator {
        background-color:#13984b;
        height: 5px;
        border-radius: 4px;

    }
    .MuiTabScrollButton-root{
        // background-color: #fff;
        // width: 25px;
        // height: 25px;
        // top:12px;
        // border-radius: 12px;
        // color:#5ea65b;
        // text-align: center;
        // box-shadow: 0px 0px 0px 4px #ddd;
    }
    
    
}
.MuiBox-root{
    background-color: #f8f8f9;
    min-height:500px;
    padding: 0 !important;
    margin-top:10px;
 
}

.MuiTypography-root{
    border-bottom: 1px solid #5ea65b;
    padding:6px;
    font-size: 20px;
    margin: 20px 0px!important;
    font-weight: 600;
    color:#000;
}

.MuiButtonBase-root{
    .MuiSvgIcon-root{
        left:-50px;
        color:#13984b
    }
}
}