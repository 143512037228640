.acc_holder{
    .makeStyles-root-11{
        box-shadow: 0px 1px 2px 1px #ddd;
    }
    .MuiAccordionSummary-content{
        display: inline-block;
    }
    .crs_title{
        color:#13984b;
        font-weight:600;
        font-size:24px;
    }
    h6{
        font-weight: 600;
        cursor:pointer;
        font-size:18px;
    }
    .crs_head{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding:5px;
        margin:17px 0;
    }
    .makeStyles-root-6{
        box-shadow: 0 2px 16px 4px rgba(40, 44, 63, 0.05);
    }
    .MuiButtonBase-root{
         .MuiTypography-root{
        font-size:14px;
        font-weight: 500;
        flex-basis: 100%;
        font-family: "Work Sans", sans-serif;
     }
        .MuiIconButton-label{
           width:20px
        }
    }
    .MuiPaper-rounded{
        margin: 0!important;
    }
    .MuiAccordionDetails-root {
        padding: 0px 47px 16px;
    }

    .MuiAccordion-root{
        box-shadow: 0 2px 16px 4px rgba(40, 44, 63, 0.09);
    }

   
}
    