.practitioner-main-area {
    .partner-logo {
        display: block;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        margin-left: 8px;
        width: 188px;
        height: 105px;
    }
    .ioe-logo {
        background-image: url("../../assets/imgs/partner-logos/ieit-logo.jpg");
    }
    .ce-logo {
        background-image: url("../../assets/imgs/CE_logo.svg");
        width: 297px;
        margin-left: 15px;
        @media only screen and (max-width: 576px) {
            width: 170px;
        }
        height: 74px;
    }

    .prac-row {
        justify-content: space-between;
        align-items: center;
        .currency-convertor {
            margin-right: 15px;
            height: 40px;
            width: 160px;
            @media (max-width: 576px) {
                display: none;
            }
        }
    }

    .card-innermost-container:hover {
        box-shadow: none!important;
    }

    .shadow-row:hover {
        box-shadow: 0 2px 16px 4px rgba(40, 44, 63, 0.15)!important;
    }
}
