.course-search-main-area {
    .outer-container {
        ::-webkit-scrollbar {
            width: 5px;
        }

        /* Track */
        ::-webkit-scrollbar-track {
            background: #f1f1f1;
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
            background: #888;
        }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
            background: #555;
        }

        .heading-container {
            background-color: #f2f2f2;
            padding: 15px;
            h4 {
                font-weight: 100;
            }
        }

        .filter-by-container {
            position: sticky;
            width: 100%;
            top: 200px;
            border: 1px solid #ebebeb;
            border-radius: 4px;
            .heading-clear-btn-container {
                display: flex;
                margin: 14px 14px 4px;
                align-items: center;
                justify-content: space-between;
                .filter-type-heading {
                }
                .clear-btn {
                    border: none;
                    background-color: transparent;
                    padding: 0;
                    font-size: 12px;
                }
            }
            .filter-inner-container {
                max-height: 70vh;
                overflow-y: scroll;
                border-bottom: 5px solid #f1f1f1;
                border-left: 5px solid #f1f1f1;
                .filter-type-container {
                    display: flex;
                    padding: 0px 15px;
                    justify-content: space-between;
                    .filter-label {
                        text-transform: capitalize;
                        width: calc(100% - 20px);
                    }
                    .filter-radio-input {
                        position: static;
                        height: 17px;
                        width: 17px;
                        cursor: pointer;
                    }
                }
            }
        }
        .no-available-courses {
            text-align: center;
            margin-top: 100px;
        }
    }
}
