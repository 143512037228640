.course-detail-container {
    margin-top: 30px;
    .main-heading {
        font-size:24px;
        padding-bottom: 7px;
        font-weight:600;
        margin-top: 7px;
        color:#13984b;
    }

    .sub-heading {
        font-size:24px;
        padding: 20px 0 5px;
        font-weight:600;
        color:#13984b;
    }
    .sub-text{
        ul{
            list-style: unset;
            padding-left: 15px;
        }
    }
    .course-details-section {
        margin-top: 50px;
        // padding: 40px 0 65px;
        p {
            font-size: 15px;
            line-height:24px;
        }
        // @media (max-width: 768px) {
        //     .course-details-row {
        //         flex-direction: column-reverse;
        //     }
        // }
        @media (max-width: 576px) {
            padding: 0 15px 30px;
        }
        .course-duration {
            font-weight: 300;
        }
    }

    .featured-courses-section {
        padding: 50px 0;
        background-color: #f8f8f9;
        .col-md-12 {
            overflow: hidden;
        }
        .explore-course-btn-container {
            display: flex;
            justify-content: center;
            .explore-course-btn {
                text-transform: uppercase;
            }
        }

        .card-layout {
            // max-width: 288px;
            margin: 0 auto;
        }
    }

    .client-section {
        padding: 50px 0;
        // margin-bottom: -65px;
        .container{
            background: #fff;
            padding-top: 30px;
        }
    }

    .loading-text {
        margin-top: 30%;
    }

    a[aria-expanded="true"] .fa-chevron-right {
        display: none !important;
    }
    a[aria-expanded="false"] .fa-chevron-down {
        display: none !important;
    }
}

.brief-section {
    padding: 50px 0;
    background: rgb(7, 10, 52);
    background: linear-gradient(135deg, rgba(7, 10, 52, 1) 0%, rgba(82, 132, 184, 1) 100%);

    @media (max-width: 768px) {
        .row {
            flex-direction: column-reverse;
        }
    }
    .course-title {
        color: #fff;
        font-size: 32px;
        font-weight: 600;
        line-height: 33px;
        @media (max-width: 768px) {
            font-size: 20px;
            line-height: 24px;
        }
    }
    .certification-container {
        display: flex;
        align-items: center;
        margin-top: 20px;
        .partner-logo {
            width: 90px;
            margin-right: 15px;
            background-color: #fff;
            border-radius: 6px;
            padding: 6px 7px 12px;
        }
        .certificate-title {
            font-size: 15px;
            color: #fff;
            font-weight: 500;
        }
    }
    .cost-seats-container {
        display: flex;
        margin: 20px 0;
        .cost-container {
            .cost-title,
            .cost {
                color: #fff;
                font-weight: 600;
            }
            .cost {
                font-weight: 500;
            }
        }
        .seats-container {
            margin-left: 20px;
        }
    }
    .subs_btn_holder{
        width:200px;
        .cart-quantity-container{
            .add-to-cart-btn,.remove-cart-btn{
                border-radius: 5px!important;
                border: none!important;
            }
            
        }
      
    }

    @media (max-width: 1200px) {
        .video-section-course {
            margin-top: 50px;
        }
    }

    .remove-cart-btn {
        color: #f3c565;
    }
    @media (max-width: 768px) {
        .video-section-course {
            margin: -45px -15px 15px;
        }
        .add-to-cart-btn {
            font-size: 15px;
            padding: 10px 55px;
            margin: 0 auto;
        }
        .remove-cart-btn {
            font-size: 15px;
            // padding: 10px 55px;
            margin: 0 auto;
        }
        .quantity-changer {
            margin: 15px auto;
        }
    }
}
