.contact-us-area {
  margin: 0 auto;
  // margin-top: 130px;
  .error-msg {
    color: red;
    padding: 0;
  }
  .location-content {
    align-items: center;
    min-height: 62vh;
  }
  .contact-form-section {
    .text-row{
      display: flex;
    justify-content: space-evenly;
    @media (max-width: 767px) {
      display: block;
    }
    }
    h2{
      font-size: 35px;
    }
    .heading-25-white{
      font-size: 25px;
      font-weight: 500;
    }
    .text-content{
      margin-top: 35px;
      text-align: left;
    }
    .para-18-white{
      font-size: 18px;
    }
    .anchor-txt{
      text-decoration: underline!important;
  }
    a {
      color: $theme-color !important;
    }
    padding-bottom: 90px;
    padding-top: 1px;
    @media (max-width: 767px) {
      padding-left: 30px !important;
      padding-right: 30px !important;
    }

    .heading-container {
      margin: 150px auto 50px;
    }
    .location-area {
      .wa {
        background-color: #50a950;
        .city {
          color: #fff;
          font-size: 35px;
          padding-bottom: 25px;
        }
        .address {
          color: #fff;
          line-height: 30px;
          font-size: 22px;
        }
        .email1,
        .email2 {
          line-height: 30px;
          font-size: 18px;
        }
      }
      .ny {
        background-color: #fff;
        .city {
          color: $black;
          font-size: 35px;
          padding-bottom: 25px;
        }
        .phone {
          color: $black;
          line-height: 30px;
          font-size: 22px;
        }
        .email1,
        .email2 {
          line-height: 30px;
          font-size: 18px;
        }
        .address {
          color: $black;
          line-height: 30px;
          font-size: 22px;
        }
      }
      .ba {
        background-color: $black;
        .city {
          color: #fff;
          font-size: 35px;
          padding-bottom: 25px;
        }
        .address {
          color: #fff;
          line-height: 30px;
          font-size: 22px;
        }
        .email1,
        .email2 {
          line-height: 30px;
          font-size: 18px;
        }
      }
    }
    .form-row {
      .input-container {
        display: flex;
        flex-direction: column;
        margin-top: 33px;
        label {
          font-weight: 200;
        }
        .contact-input {
          height: 50px;
          width: 97%;
          padding: 15px;
          background-color: #fafafa;
          border-radius: 3px;
          border: 1px solid #707070;
          font-weight: 200;
          option {
            font-weight: 200;
          }
        }
      }
      @media (max-width: 992px) {
        .input-container {
          margin-top: 10px;

          .contact-input {
            height: 45px;
            padding: 8px;
            font-weight: 200;
          }
        }
      }
    }
    .agreement-row {
      margin: 30px 0px;
      .error-msg {
        color: red;
      }
      .checkbox-container {
        margin-top: 19px;
        display: flex;
        .checkbox {
          height: 20px;
          margin-top: 8px;
          width: 30px;
          margin-right: 10px;
          @media (max-width: 767px) {
            width: 60px !important;
          }
        }
        p {
          margin-top: 8px;
        }
      }
      .info {
        margin-top: 17px;
      }
    }
    .reCaptcha-container {
      display: flex;
      align-items: center;
      margin-top: 41px;
      .submit-form-button {
        display: block;
      }
    }
  }
}
