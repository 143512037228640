.mog-container {
    padding: 50px 0;
    max-width: 1200px!important;
    margin: 0 auto;
    min-height: 600px;
    .mog-intro {
        font-size: 18px;
    }

    .react-player {
        height: 350px !important;
    }

    .collection-heading {
        font-weight: 500;
        font-size: 20px;
        @media (max-width: 992px) {
            font-size: 15px;
            line-height: 20px;
        }
        @media (max-width: 576px) {
            margin-left: -11px;
        }
    }

    .course-row {
        margin-top: 60px;
        .collection-row {
            border-bottom: 1px solid #d8d8d8;
            margin-bottom: 20px;
            @media (max-width: 576px) {
                padding-bottom: 20px;
            }
            .collection-container {
                border-bottom: 1px solid #d8d8d8;
                position: relative;
                top: 1px;
                .heading-btn-container {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-top: 30px;
                    @media (max-width: 992px) {
                        margin-top: 15px;
                    }
                    .show-all-btn {
                        border: none;
                        background-color: transparent;
                        font-weight: 500;
                        color: #8f8f8f;
                        text-align: right;

                        @media (max-width: 992px) {
                            font-size: 11px;
                            line-height: 18px;
                            width: 80px;
                            margin-right: -11px;
                        }
                    }
                }
            }
            @media (max-width: 768px) {
                .card-view {
                    padding-bottom: 12px;
                }
            }
            .card-view:nth-child(odd) {
                border-right: 1px solid #d8d8d8;
                @media (max-width: 768px) {
                    border: none;
                    border-bottom: 1px solid #d8d8d8;
                }
            }
        }
    }
}
