/* =========================== video-section =========================== */
.video-section {
  position: relative;
  background-color: $black;
  width: 100%;
  z-index: 1;
  .app {
    width: 100%;
    .player-wrapper {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
      border-radius: 3px;
      position: relative;
      height: auto;
      width: 100%;
    }
  }

  .video-controls {
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
    // width: calc(100% - 1px);
    width: 100%;

    background-color: rgba(243, 197, 101, 0.4);
    height: 30px;
    border-radius: 0 0 3px 3px;
    z-index: 1;

    .play_btn,
    .pause_btn {
      height: 30px;
      background-color: $theme-color;
      width: 45px;
      background-size: 29%;
      background-repeat: no-repeat;
      background-position: center;
      cursor: pointer;
      border-radius: 0 0 0 3px;
      border: none;
    }
    .play_btn {
      background-image: url("../imgs/play-icon-small.svg");
    }
    .pause_btn {
      background-image: url("../imgs/pause-icon.svg");
    }
    .current_time {
      font-size: 14px;
      position: absolute;
      left: 55px;
      z-index: 1;
      color: #fff;
      margin: 0;
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        margin-top: -8px;
      }
    }
    .video_scrubber_container {
      display: flex;
      align-items: center;
      width: calc(100% - 44px);
      position: relative;
      height: 30px;
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        margin-top: -30px;
      }
      .video_scrubber,
      .progress_bar {
        position: absolute;
        box-shadow: none !important;
        // left: 44px;
        // z-index: -1;
      }
      .video_scrubber {
        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
          // visibility: hidden;
          opacity: 0;
        }
      }
      .progress_bar {
        z-index: -1;
        background-color: rgba(243, 197, 101, 0.6);
        height: 30px;
      }

      input[type="range"] {
        -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
        width: 100%; /* Specific width is required for Firefox. */
        background: transparent; /* Otherwise white in Chrome */
        cursor: pointer;
        outline: none;
        border: none;
      }

      input[type="range"]::-webkit-slider-thumb {
        -webkit-appearance: none;
      }

      input[type="range"]:focus {
        outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
      }

      input[type="range"]::-ms-track {
        width: 100%;
        cursor: pointer;

        /* Hides the slider so custom styles can be added */
        background: transparent !important;
        border-color: transparent !important;
        color: transparent !important;
      }

      input[type="range"]::-webkit-slider-thumb {
        -webkit-appearance: none;
        // border: 1px solid #000000;
        height: 36px;
        width: 16px;
        border-radius: 3px;
        // background: #ffffff;
        cursor: pointer;
        // display: none;
        visibility: hidden;
        margin-top: -14px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
        // box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d; /* Add cool effects to your sliders! */
      }

      /* All the same stuff for Firefox */
      input[type="range"]::-moz-range-thumb {
        box-shadow: 1px 1px 1px #00000000, 0px 0px 1px #0d0d0d00;
        box-shadow: none;
        // border: 1px solid #000000;
        visibility: hidden;
        height: 36px;
        width: 16px;
        border-radius: 3px;
        cursor: pointer;
        visibility: hidden;
      }

      /* All the same stuff for IE */
      input[type="range"]::-ms-thumb {
        // box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
        // border: 1px solid #000000;
        height: 36px;
        width: 16px;
        border-radius: 3px;
        cursor: pointer;
        visibility: hidden;
      }

      input[type="range"]::-webkit-slider-runnable-track {
        width: 100%;
        height: 30px;
        cursor: pointer;
        // box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
        border-radius: 1.3px;
        border: 0.2px solid #010101;
        visibility: hidden;
      }

      input[type="range"]:focus::-webkit-slider-runnable-track {
        // background: #367ebd;
        visibility: hidden;
      }

      input[type="range"]::-moz-range-track {
        width: 100%;
        height: 8.4px;
        cursor: pointer;
        box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
        // background: #3071a9;
        border-radius: 1.3px;
        border: 0.2px solid #010101;
      }

      input[type="range"]::-ms-track {
        width: 100%;
        height: 8.4px;
        cursor: pointer;
        background: transparent;
        border-color: transparent;
        border-width: 16px 0;
        color: transparent;
      }
      input[type="range"]::-ms-fill-lower {
        // background: #2a6495;
        border: 0.2px solid #010101;
        border-radius: 2.6px;
        box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
      }
      input[type="range"]:focus::-ms-fill-lower {
        // background: #3071a9;
      }
      input[type="range"]::-ms-fill-upper {
        // background: #3071a9;
        border: 0.2px solid #010101;
        border-radius: 2.6px;
        box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
      }
      input[type="range"]::-ms-track {
        width: 100%;
        cursor: pointer;

        /* Hides the slider so custom styles can be added */
        background: transparent;
        border-color: transparent;
        color: transparent;
      }
    }
    .volume_btn,
    .speed_btn {
      background-color: $theme-color;
      height: 30px;
      width: 46px;
      cursor: pointer;
      border: none;
    }
    .volume_btn {
      // background-image: url("../imgs/volume-icon.svg");
      background-size: 34%;
      background-repeat: no-repeat;
      background-position: center;
      position: relative;
      input[type="range"][orient="vertical"] {
        display: none;
        writing-mode: bt-lr; /* IE */
        -webkit-appearance: slider-vertical; /* WebKit */
        position: absolute;
        bottom: 30px;
        left: 15px;
        height: 80px;
        width: 10px;
        cursor: pointer;
      }
    }

    .volume_btn:hover {
      input {
        display: block !important;
      }
    }
    .speed_btn {
      color: #fff;
      border-radius: 0 0 3px 0;
      font-size: 14px;
    }
  }

  // @media (min-width: 1920px) {
  //   .player-wrapper {
  //     height: 778px;
  //   }
  // }

  // @media (min-width: 1536px) and (max-width: 1919px) {
  //   .player-wrapper {
  //     // height: 488px;
  //     height: 768px;
  //     // height: 100%;
  //   }
  // }

  // @media (min-width: 576px) and (max-width: 1365px) {
  //   .player-wrapper {
  //     // height: 444px;
  //     height: 450px;
  //   }
  // }

  // @media (max-width: 575px) {
  //   .player-wrapper {
  //     height: 316px;
  //   }

  // }

  .video-text-container {
    display: block;
    position: absolute;
    z-index: 10;
    width: 100%;
    height: 100%;
    border-radius:5px;
  }
  .overlay-text {
    position: absolute;
    z-index: 10;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 2%;
    .player-btn {
      transition: all 0.3s ease;
      background-image: url("../imgs/play-btn.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      height: 50px;
      width: 50px;
      border: none;
      background-color: transparent;
      margin-top: 19px;
      cursor: pointer;
      margin: 0 auto;

      @media (max-width: 576px) {
        height: 60px;
        width: 60px;
      }
    }
  }

  .video-overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: #000000;
    opacity: 0.5;
    z-index: 1;
    border-radius: 5px;
  }

  h4 {
    color: #fff;
    text-align: center;
    // font-size: 35px;
    padding-bottom: 7px;
    width: auto;
    margin: 6px auto;
    font-weight: 500;
    font-size: 15px;
  }

  @media (max-width: 767px) {
    h4 {
      width: auto;
    }
  }

  .video-inner-overlay {
    position: absolute;
    width: 100%;
    z-index: 0;
    z-index: 8;
    display: flex;
    align-items: center;
    height: 89%;
  }

  .video-poster {
    height: 100%;
    width: 100%;
    background-size: cover;
    position: absolute;
    background-position: center;
  }
  .fscreen {
    height: 33px;
    width: 41px;
    background-repeat: no-repeat;
    background-color: transparent;
    border: none;
    background-size: contain;
    background-size: 35%;
    height: 100%;
    background-position: center;
    background-color: $theme-color;
    cursor: pointer;
  }
  .full-screen-btn {
    background-image: url("../imgs/fullscreen-icon.svg");
  }
  .exitfull-screen-btn {
    background-image: url("../imgs/exit-full-screen.svg");
  }

  // =================================
}

.video-section:hover {
  .player-btn {
    transform: scale(1.1);
    transition: all 0.3s ease;
  }
}
