.filter-bar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0px;
    // border-bottom: 1px solid #ebebeb;
    width: 100%;
    background-color: #f8f8f9;
    // z-index: 5;
    // position: fixed;
    // top: 105px;
    @media (max-width: 1200px) {
        flex-direction: column;
        align-items: baseline;
    }
    .title {
        // margin-left: 15px;
        font-weight: 500;
        // padding: 13px 0;
        @media (max-width: 1200px) {
            font-size: 18px;
            padding: 10px 0;
            margin-left: 15px;
        }
    }
    .filter-right-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        // padding-left: 15px;
        margin-right: -1px;

        @media (max-width: 1200px) {
            width: 100%;
            // border-top: 1px solid #ebebeb;
            padding-left: 0;
        }

        .switch-container {
            display: flex;
            align-items: center;
            border-left: 1px solid #ebebeb;
            height: 55px;
            padding: 0 15px;

            @media (max-width: 576px) {
                transform: scale(0.8);
                padding: 0;
                border-left: none;
            }

            .member-text {
                margin-left: 15px;
                font-weight: 500;
            }
            .non-member-text {
                margin-right: 10px;
                font-weight: 500;
            }
        }

        .filter-dropdown {
            display: flex;
            align-items: center;
            margin-right: 20px;
            position: relative;
            border-left: 1px solid #ebebeb;
            height: 55px;
            padding-left: 15px;
            @media (max-width: 576px) {
                padding-left: 0;
                margin-right: 15px;
                border-left: none;
            }
            .courses-heading {
                @media (max-width: 576px) {
                    display: none;
                }
            }

            .filter-dropdown-inner-container {
                margin-left: 17px;
                @media (max-width: 576px) {
                    margin-left: 5px;
                    padding: 20px 0;
                }
                .filter-dropdown-btn {
                    border: 1px solid #f3c465;
                    background-color: #f3c465;
                    padding: 3px 12px;
                    border-radius: 3px;
                    width: 320px;
                    font-weight: 500;
                    @media (max-width: 576px) {
                        width: 200px;
                        font-size: 11px;
                        width: calc(100vw - 120px);
                        border: none;
                    }
                    color: $black;
                    text-align: left;
                    background-repeat: no-repeat;
                    background-image: url("../../assets/imgs/chevron-down-grey.svg");
                    background-size: 10px;
                    background-position: 98% 50%;
                }
                .func-role-selection-container {
                    display: none;
                    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2);
                    position: absolute;
                    top: 44px;
                    width: 320px;
                    @media (max-width: 576px) {
                        width: 100vw;
                        margin-left: -6px;
                        margin-top: 12px;
                    }
                    @media (max-height: 576px) {
                        height: 165px;
                        overflow: scroll;
                    }

                    .func-role-selection-option {
                        display: flex;
                        align-items: center;
                        background-color: #fff;

                        @media (max-width: 576px) {
                            margin: -3px 0;
                        }
                        .role-check-input {
                            width: 15px;
                            min-width: 15px;
                            margin: 0 10px 0 15px;
                        }
                        button {
                            line-height: 20px;
                            text-align: left;
                            border: none;
                            background-color: transparent;
                            @media (max-width: 576px) {
                                width: 200px;
                            }
                        }
                    }
                    .toggle-all {
                        .toggle-all-text {
                            font-weight: 600;
                        }
                    }

                    .subscription-all {
                        border-bottom: 1px solid #ebebeb;

                        .subscription-all-text {
                            font-weight: 600;
                        }
                    }

                    .companion-all {
                        border-top: 1px solid #ebebeb;

                        .companion-all-text {
                            font-weight: 600;
                        }
                    }
                }
            }
            .filter-dropdown-inner-container:hover .func-role-selection-container {
                display: block;
            }
        }

        .btn-container {
            display: flex;
            align-items: center;
            width: 60px;
            .card-view-btn,
            .list-view-btn {
                width: 50px;
                height: 56px;
                background-color:#f8f8f9;
                border: none;
                // border-left: 1px solid #ebebeb;
                background-repeat: no-repeat;
                background-position: center;
            }
            .card-view-btn {
                background-image: url("../imgs/card-icon-grid.svg");
                margin-right: 10px;
            }
            .list-view-btn {
                background-image: url("../imgs/list-icon-grid.svg");
                // border-right: 1px solid #ebebeb;
                @media (max-width: 576px) {
                    border-right: none;
                }
            }
            .card-view-selected {
                background-image: url("../imgs/card-icon-grid-selected.svg");
            }
            .list-view-selected {
                background-image: url("../imgs/list-icon-grid-selected.svg");
            }
        }
    }
}
